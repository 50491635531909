import { Badge, Box, Divider, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { PrivateReview_Reputation as PrivateReview, GoogleReview_Reputation as GoogleReview } from "@safelease/service-utilities";
import { TenantReviews } from "./FacilityView/FacilityViewTabs/ContactsOverview/ProfileDrawer/TenantReviews";
import Close from "@mui/icons-material/Close";
import { useReputation } from "../useReputation";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PrivateTenantReviews } from "./NonPublicReplyDrawer";
import { SafeLeaseTabs, SafeLeaseTab, SafeLeaseTabContent } from "@safelease/components";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";

export enum ReviewReplyDrawerTab {
  PUBLIC = "Public Reviews",
  NONPUBLIC = "Private Reviews",
}


interface ReviewReplyDrawerProps {
  publicReviews: Array<GoogleReview>;
  privateReviews: Array<PrivateReview>;
  presetTab?: ReviewReplyDrawerTab;
}

export function ReviewReplyDrawer({
  publicReviews = [],
  privateReviews = [],
  presetTab = ReviewReplyDrawerTab.PUBLIC,
}: ReviewReplyDrawerProps) {
  // get tab to show from the URL query parameters if provided
  const queryParamReviewReplyDrawerTab = new URLSearchParams(useLocation().search).get("reviewReplyDrawerTab");
  if (queryParamReviewReplyDrawerTab) {
    presetTab = queryParamReviewReplyDrawerTab === "public" ? ReviewReplyDrawerTab.PUBLIC : ReviewReplyDrawerTab.NONPUBLIC;
  }

  const { reviewReplyDrawerOpen, setReviewReplyDrawerOpen } = useReputation();
  const [currentTab, setCurrentTab] = useState<ReviewReplyDrawerTab>(presetTab);

  // When landing on the facility overview page, if there is a Query Parameter to open the drawer, open it on first render
  useEffect(() => {
    if (queryParamReviewReplyDrawerTab) {
      setReviewReplyDrawerOpen(true);
    }
  }, []);

  const handleClose = () => {
    setReviewReplyDrawerOpen(false);
  };


  const privateReviewsPendingReply = privateReviews.filter((review) => !review.responseSnippet);

  return (
    <Drawer open={reviewReplyDrawerOpen} onClose={handleClose} PaperProps={{ sx: { width: 700, p: 2 } }} anchor="right">
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
        <Box>
          <Typography variant="h6" fontWeight="bold">
            Reviews Pending Reply
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <SafeLeaseTabs
        sx={{ mt: 1 }}
        onChange={(_, value) => {
          setCurrentTab(value);
        }}
        value={currentTab}
      >
        <SafeLeaseTab
          label={ReviewReplyDrawerTab.PUBLIC}
          value={ReviewReplyDrawerTab.PUBLIC}
          icon={
            <Badge badgeContent={publicReviews.length} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
              <PublicIcon />
            </Badge>
          }
        />
        <SafeLeaseTab
          label={ReviewReplyDrawerTab.NONPUBLIC}
          value={ReviewReplyDrawerTab.NONPUBLIC}
          icon={
            <Badge badgeContent={privateReviewsPendingReply.length} color="primary" anchorOrigin={{ vertical: "top", horizontal: "left" }}>
              <PublicOffIcon />
            </Badge>
          }
        />
      </SafeLeaseTabs>
      <Box sx={{ py: 2 }}>
        <SafeLeaseTabContent value={currentTab} currentTab={ReviewReplyDrawerTab.PUBLIC}>
          <TenantReviews />
        </SafeLeaseTabContent>
        <SafeLeaseTabContent value={currentTab} currentTab={ReviewReplyDrawerTab.NONPUBLIC}>
          <PrivateTenantReviews reviews={privateReviewsPendingReply} />
        </SafeLeaseTabContent>
      </Box>
    </Drawer>
  );
}

