import { Rating, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import GoogleLogo from "../../../../../assets/google-logo.png";
import { Review } from "@safelease/service-utilities";
import dayjs from "dayjs";
import { GeneratedResponseModal } from "../../../../../components/GeneratedResponseModal";
import { useState } from "react";
import { MagicWandIcon } from "../../../../../assets/magicWandIcon";

interface GoogleReviewCardProps {
  review: Review;
}

/* Display a review by a user on Google. Include their profile picture, rating, and the text of their review */
export function GoogleReviewCard({ review }: GoogleReviewCardProps) {
  const googleBusinessProfileManagerReviewsTabLink = "https://business.google.com/reviews";
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add buttons to control modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <Typography variant="body1">{review.facility.companyName}</Typography> */}
          </Stack>
          <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
            {dayjs(review.actualDate).format("MMM DD, YYYY")}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <img src={GoogleLogo} alt="Google Logo" style={{ height: 20, width: 20 }} />
            <Rating value={review.rating} readOnly />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="body2">{review.reviewSnippet}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
              {review.googleReviewUser?.name} {review.campaign ? ` - ${review.campaign?.name} Campaign` : ""}
            </Typography>
          </Stack>
          <Stack>
            <GeneratedResponseModal
              reviewId={review.id}
              open={isModalOpen}
              onClose={handleCloseModal}
              reviewType="google"
              review={review}
            />
            <Stack direction="row" spacing={1}>
              <SafeLeaseButton
                variant="contained"
                onClick={() => window.open(googleBusinessProfileManagerReviewsTabLink, "_blank")}
              >
                Respond on Google Business
              </SafeLeaseButton>
              <SafeLeaseButton
                variant="outlined"
                onClick={handleOpenModal}
                >
                <MagicWandIcon style={{ marginRight: 8 }}/>
                Generate Response
              </SafeLeaseButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}
