import { Button, Divider, Grid, Stack } from "@mui/material";
import { blackButton, muiRedOutlineButton } from "../styles/mui-overrides";
import { Box } from "@mui/system";
import { useUserManagementSettings } from "../settings/components/UserSettings/useUserManagementSettings";
import LoadingButton from "@mui/lab/LoadingButton";

interface DrawerFooterProps {
  editMode: EditMode;
  drawerType: DrawerType;
  setDeleteDialogOpen?: (event: any) => void;
}

function DrawerFooter({
  setDeleteDialogOpen
}: DrawerFooterProps) {

  const { editDrawerOpenMode, loading } = useUserManagementSettings();

  return (
    <footer
      style={{
        color: "gray",
        position: "fixed",
        bottom: 0,
        padding: 0,
        width: 833,
      }}
    >
      <Box sx={{ width: 773 }}>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
          >
          {editDrawerOpenMode === EditMode.Edit && (
            <Button
              sx={muiRedOutlineButton()}
              onClick={() => setDeleteDialogOpen(true)}
              disabled={loading}
              >
              Delete
            </Button>
          )}
          <LoadingButton
            loading={loading}
            type="submit"
            sx={{
              ...blackButton,
              backgroundColor: "#152744",
              marginLeft: editDrawerOpenMode === EditMode.Add ? 'auto' : 0 // right align on add since delete button is hidden
            }}
            disabled={loading}
            >
            {editDrawerOpenMode === EditMode.Add ? "Create" : "Save"}
          </LoadingButton>
        </Stack>
      </Box>
    </footer>
  );
}

export enum EditMode {
  Add = "Add",
  Edit = "Edit",
  Notifications = "Notifications",
}

export enum DrawerType {
  ClaimDetails = "ClaimDetails",
  EmailSettings = "EmailSettings",
  UserSettings = "UserSettings",
}

export default DrawerFooter;
