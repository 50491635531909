/* UI - Libs */
import { Box, Checkbox, Grid, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

/* Hooks */
import { useReportsStore } from "../../reports/useReportsStore";

/* Data */
import { Location } from "../../utilities/generated/gql-types";

interface LocationSelectorDropdownItemProps {
  /* The location object to render data from */
  location: Location;
}

/* Multi location selector dropdown menu */
/* TODO: Refactor to be a reusable component */
export default function LocationSelectorMenuItem({
  location,
}: LocationSelectorDropdownItemProps) {
  /* Manage toggling this a location's inclusion in the daily summary report */
  const selectedLocationsInReport = useReportsStore(
    (state) => state.selectedLocationsInReport
  );

  const setSelectedLocationsInReport = useReportsStore(
    (state) => state.setSelectedLocationsInReport
  );

  /* Add / remove this location from the daily summary report */
  const handleLocationToggle = () => {
    if (selectedLocationsInReport.includes(Number(location.id))) {
      setSelectedLocationsInReport(
        selectedLocationsInReport.filter(
          (selectedLocationId) => selectedLocationId !== Number(location.id)
        )
      );
    } else {
      setSelectedLocationsInReport([
        ...selectedLocationsInReport,
        Number(location.id),
      ]);
    }
  };

  return (
    <ListItem>
      <ListItemText primary={location.name ? `${location.address} - ${location.name}` : location.address} />
      <ListItemIcon>
        <Checkbox
          size="small"
          checked={selectedLocationsInReport.includes(Number(location.id))}
          onChange={handleLocationToggle}
        />
      </ListItemIcon>
    </ListItem>
  )
}
