// UI - internal
import { muiDrawer } from "../../../../styles/mui-overrides";

// Data
import { Location, User } from "../../../../utilities/generated/gql-types";
import { Box } from "@mui/system";
import EditUserForm from "./EditUserForm";
import { useUserManagementSettings } from "../useUserManagementSettings";
import { EditMode } from "../../../../shared/drawer-footer";
import AddUserForm from "./AddUserForm";
import Notifications from "./Notifications";

interface UserSettingsDrawerProps {
  currentlyEditedUser: User | null;
  locations: Location[];
  relationshipId: number;
}

export default function UserSettingsDrawer({
  currentlyEditedUser,
  locations,
  relationshipId,
}: UserSettingsDrawerProps) {

  const { editDrawerOpenMode } = useUserManagementSettings();

  return (
    <Box
      role="presentation"
      sx={muiDrawer}
      className="claim-details claim-details__email-settings-drawer"
    >
      {editDrawerOpenMode === EditMode.Edit && (
        <EditUserForm
          currentlyEditedUser={currentlyEditedUser}
          locations={locations}
          relationshipId={relationshipId}
        />
      )}
      {editDrawerOpenMode === EditMode.Add && (
        <AddUserForm
          currentlyEditedUser={currentlyEditedUser}
          locations={locations}
          relationshipId={relationshipId}
        />
      )}
      {editDrawerOpenMode === EditMode.Notifications && <Notifications userId={currentlyEditedUser.id} />}
    </Box>
  );
}
