import { Box, Dialog, Divider, Typography } from "@mui/material";
import { ScheduleUpgradeForm } from "./ScheduleUpgradeForm";
import { RescheduleUpgradeForm } from "./RescheduleUpgradeForm";

export enum DialogType {
  SCHEDULE_UPGRADE = "scheduleUpgrade",
  RESCHEDULE_UPGRADE = "rescheduleUpgrade",
}
interface ScheduleUpgradeFormDialogProps {
  type: DialogType; 
  handleClose: () => void;
}

export function ScheduleUpgradeFormDialog({
  type,
  handleClose,
}: ScheduleUpgradeFormDialogProps) {
  const title =
    type === DialogType.SCHEDULE_UPGRADE
      ? "Schedule New Plan Upgrade Job"
      : "Reschedule Existing Plan Upgrade Job";

  return (
    <Dialog open={type === DialogType.SCHEDULE_UPGRADE || type === DialogType.RESCHEDULE_UPGRADE} onClose={handleClose} fullWidth maxWidth="md">
      <Box sx={{ p: 4 }}>
        <Typography variant="h5">{title}</Typography>
        <Divider sx={{ my: 4 }} />
        {type === DialogType.SCHEDULE_UPGRADE && <ScheduleUpgradeForm handleClose={handleClose}/>}
        {type === DialogType.RESCHEDULE_UPGRADE && <RescheduleUpgradeForm handleClose={handleClose}/>}
      </Box>
    </Dialog>
  );
}
