import { useState } from "react";
import { Button, Stack, LinearProgress } from "@mui/material";
import { usePlanUpgradeStore } from "../usePlanUpgradeSettingsStore";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import DatePicker from "../../../components/lib/SafeLeaseDatePicker";
import dayjs from "dayjs";
import PlanUpgradesService from "../../../services/plan-upgrades.service";
interface RescheduleUpgradeProps {
  handleClose: () => void;
}

function RescheduleUpgradeForm({ handleClose }: RescheduleUpgradeProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedPlanId } = usePlanUpgradeStore();
  const [date, setDate] = useState(null);

  if (!selectedPlanId) handleClose();

  const rescheduleUpgradeMutation = useMutation({
    mutationFn: ({ upgradeProtectionPlanConfigId, upgradeDate }: { upgradeProtectionPlanConfigId: number; upgradeDate: string }) =>
      PlanUpgradesService.rescheduleUpgrade({
        upgradeProtectionPlanConfigId,
        upgradeDate,
      }),
    // Once a plan's upgrade date is rescheduled, there could be up to a 15 second delay before the UI reflects the change.
    // This is because the UI is refreshed every 15 seconds. See PlanUpgradesOverview.tsx for more details.
    // We could force a UI refresh here, but it's not necessary because the UI will refresh itself in at most 15 seconds.
    onSuccess: () => {
      enqueueSnackbar("Plan upgrade rescheduled successfully.", { variant: "success" });
      handleClose();
    },
    onError: () => {
      enqueueSnackbar("Plan upgrade reschedule failed.", { variant: "error" });
      handleClose();
    },
  });

  const handleRescheduleJobClick = () => {
    rescheduleUpgradeMutation.mutate({
      upgradeProtectionPlanConfigId: +selectedPlanId,
      upgradeDate: date.toISOString(),
    });
  };

  return (
    <Stack direction="column" spacing={2}>
      {rescheduleUpgradeMutation.isLoading && <LinearProgress />}
      <DatePicker
        value={date}
        onChange={(newDate) => {
          const dayJsDate = dayjs(newDate);
          const selectedDayOfMonthAt9AM = dayJsDate.hour(9).minute(0).second(0);
          setDate(selectedDayOfMonthAt9AM.toDate());
        }}
        label="Select a reschedule date"
        views={["year", "month", "day"]}
        disablePast
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleRescheduleJobClick}
        disabled={rescheduleUpgradeMutation.isLoading || !date}
      >
        Reschedule Job
      </Button>
    </Stack>
  );
}

export { RescheduleUpgradeForm };
