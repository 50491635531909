import { Alert, Checkbox, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";
import { Theme } from "@mui/material/styles";
import { useState } from "react";
import { useAuth } from "../../auth";
import { reactQueryClient } from "../../utilities/reactQueryClent";
import { enqueueSnackbar } from "notistack";
import { useReputation } from "../useReputation";
import { GeneratedResponseModal } from "./GeneratedResponseModal";
import { MagicWandIcon } from "../assets/magicWandIcon";

export interface FeedbackResponseProps {
  feedback: PrivateReview;
  copyEmailButtonProps?: Partial<React.ComponentProps<typeof SafeLeaseButton>>;
  generateResponseButtonProps?: Partial<React.ComponentProps<typeof SafeLeaseButton>>;
}

export function FeedbackResponseActionArea({
  feedback,
  copyEmailButtonProps,
  generateResponseButtonProps
}: FeedbackResponseProps) {
  const [loading, isLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(Boolean(feedback.responseSnippet));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useAuth();
  const { SafeLeaseAPI } = useReputation();

  const email = feedback.contact?.email;
  const clipboardSupported = navigator.clipboard && navigator.clipboard.writeText;

  const handleEmailAddressCopyClick = () => {
    // Copy email address to clipboard
    navigator.clipboard.writeText(feedback.contact.email);
    enqueueSnackbar(`${feedback.contact.email} copied to clipboard`, { variant: "success" });
  };

  const handleToggle = async () => {
    isLoading(true);
    setIsChecked(!isChecked);
    try {
      // toggle the responseSnippet and responseDate fields between null and a value saying who marked it
      const newPrivateReviewParams = {
        responseSnippet: feedback.responseSnippet ? null : `Marked as Responded by ${auth.user.email}`,
        responseDate: feedback.responseSnippet ? null : new Date(),
      };

      // update data in db
      await SafeLeaseAPI.reputation.updatePrivateReview(feedback.id, { ...newPrivateReviewParams });

      // refresh the sources of the feedback data
      await reactQueryClient.invalidateQueries({
        queryKey: ["portfolioDashboard"], // this is the query that fetches the feedback
      });
    } catch (error) {
      enqueueSnackbar("Error marking as responded. Please try again.", { variant: "error" });
      // Revert the state back to the actual value from the feedback in case of an error
      setIsChecked(Boolean(feedback.responseSnippet));
    } finally {
      isLoading(false);
    }
  };

  const handleGenerateResponse = () => {
    setIsModalOpen(true);
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-end" sx={{ my: 1 }}>
      <Stack direction="row" alignItems="center">
        <Checkbox onChange={handleToggle} checked={isChecked} />
        <Typography variant="body2">Resolved</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Divider orientation="vertical" flexItem />
        {
          // Show "No Email" if email is not available, otherwise show the button or email text
          !email ? (
            <Typography variant="body2">Email Unknown</Typography>
          ) : clipboardSupported ? (
            <>
              <SafeLeaseButton
                color="blue"
                variant="contained"
                onClick={handleEmailAddressCopyClick}
                sx={{
                  minWidth: 150,
                  ...copyEmailButtonProps?.sx,
                }}

              >
                Copy Email to Respond
              </SafeLeaseButton>
              <SafeLeaseButton
                color="blue"
                variant="outlined"
                onClick={handleGenerateResponse}
                sx={{
                  minWidth: 150,
                  ...generateResponseButtonProps?.sx,
                }}
              >
                <MagicWandIcon style={{ marginRight: 8 }} />
                Generate Response
              </SafeLeaseButton>
              <GeneratedResponseModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                reviewId={feedback.id}
                reviewType="private"
                review={feedback}
              />
            </>
          ) : (
            <Typography variant="body2">Respond to {email}</Typography>
          )
        }
      </Stack>
    </Stack>
  );
}
