import { useQuery } from "@apollo/client";
import SafeleaseDataGrid from "../../../shared/safelease-data-grid";
import { invoiceReviewTableColumnDefs } from "./invoiceReviewTableColumnDefs";
import { getInvoiceDataForReview } from "../../../queries";
import { LinearProgress } from "@mui/material";
import { Fragment } from "react";

type InvoiceReviewTableProps = {
  selectedServicePeriod: Date;
  isRefetching: boolean;
  setIsRefetching: (isRefetching: boolean) => void;
};

function InvoiceReviewTable({ selectedServicePeriod, isRefetching, setIsRefetching }: InvoiceReviewTableProps) {
  const invoiceDataForReviewQuery = useQuery(getInvoiceDataForReview, {
    variables: { servicePeriod: selectedServicePeriod },
    onCompleted: () => {
      setIsRefetching(false); 
    },
    notifyOnNetworkStatusChange: true,
  });

  if (invoiceDataForReviewQuery.error) return <>Error getting invoice data for review</>;

  const isLoading = invoiceDataForReviewQuery.loading || isRefetching;

  return (
    <Fragment>
      {isLoading && <LinearProgress />}
      <SafeleaseDataGrid
        initialState={{
          columns: {
          columnVisibilityModel: {
            locationName: false,
          },
        },
      }}
        getRowId={(row) => row.locationId}
        autosizeOnMount
        columns={invoiceReviewTableColumnDefs}
        rows={invoiceDataForReviewQuery.data?.getInvoiceDataForReview ?? []}
      />
    </Fragment>
  );
}

export { InvoiceReviewTable };
