import http from "./http-common";

class PlanUpgradesService {
  createUpgrade(data: CreateUpgradeRequestBody) {
    return http.post("/upgrade-protection-plans/create", data);
  }

  rescheduleUpgrade(data: RescheduleUpgradeDateRequestBody) {
    return http.put("/upgrade-protection-plans/update", data);
  }
}

export default new PlanUpgradesService();
