import { InfoOutlined, KeyboardArrowRight, Warning } from "@mui/icons-material";
import { Box, Button, Rating, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { SafeLeaseButton, SafeLeaseChip, SafeLeaseLetterAvatar } from "@safelease/components";
import { Review } from "@safelease/service-utilities";
import dayjs from "dayjs";
import _ from "lodash";
import { GeneratedResponseModal } from "../../../../components/GeneratedResponseModal";
import { useState } from "react";
import { MagicWandIcon } from "../../../../assets/magicWandIcon";

export const reviewsGridColumns: GridColDef<Review>[] = [
  {
    field: "googleReviewUser",
    headerName: "Reviewer",
    sortable: true,
    disableColumnMenu: true,
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="body2">Reviewer</Typography>
        <Tooltip title="Name of the person who left the review">
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
    flex: 1,
    renderCell: ({ value }) => {
      // If for some reason the google user couldn't be associated with this review, throw an error
      if (!value)
        return (
          <SafeLeaseChip
            color="red"
            size="small"
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Warning />
                <Typography>Failed to load</Typography>
              </Stack>
            }
          />
        );

      return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ m: 0.5 }}>
          <SafeLeaseLetterAvatar src={value.thumbnailUrl} name={value.name} sx={{ height: 32, width: 32, fontSize: 14 }} />
          <Box>
            <Typography variant="body2" fontWeight="semibold">
              {value.name}
            </Typography>
            <Typography variant="caption" sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>
              {value.unit}
            </Typography>
          </Box>
        </Stack>
      );
    },
    sortComparator: (v1, v2) => {
      if (!v1 || !v2) return 0;
      return v1.name.localeCompare(v2.name);
    },
  },
  {
    field: "rating",
    headerName: "Rating",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <Typography variant="body2">Rating</Typography>,
    renderCell: ({ value }) => <Rating value={value} readOnly />,
  },
  {
    field: "platform",
    headerName: "Review Site",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <Typography variant="body2">Review Site</Typography>,
    valueGetter: () => "Google",
  },
  {
    field: "actualDate",
    headerName: "Review Date",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <Typography variant="body2">Review Date</Typography>,
    valueGetter: ({ row }) => (row.actualDate ? dayjs(row.actualDate).format("MMM D, YYYY") : row.date),
    sortComparator: (v1, v2) => {
      const date1 = dayjs(v1);
      const date2 = dayjs(v2);
      return date1.diff(date2);
    },
  },
  {
    field: "campaign",
    headerName: "Campaign",
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: ({ value }) => (value ? value.name : "Organic"),
    renderHeader: () => (
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography variant="body2">Campaign</Typography>
        <Tooltip
          title='If a review was able to be associated with a campaign, the channel will be the campaign type.
        "Organic" means that we could not associate this review with a campaign.'
        >
          <InfoOutlined
            sx={{
              height: 18,
              color: (theme: Theme) => theme.palette.grey.A100,
            }}
          />
        </Tooltip>
      </Stack>
    ),
  },
  {
    field: 'generateResponse',
    headerName: 'Generate Response',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => {
      const [isModalOpen, setIsModalOpen] = useState(false);

      return (
        <>
          <Button
            variant="outlined"
            color="navy"
            onClick={() => setIsModalOpen(true)}
          >
            <MagicWandIcon style={{ marginRight: 8 }}/>
            Generate Response
          </Button>
          <GeneratedResponseModal
            reviewId={row.id}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            reviewType="google"
            review={row}
          />
        </>
      );
    }
  },
  {
    field: 'tenantFeedback',
    sortable: false,
    disableColumnMenu: true,
    align: "right",
    flex: 1,
    renderHeader: () => <Typography variant="body2">Tenant Feedback</Typography>,
    valueGetter: ({ row }) => ({ requiresResponse: !Boolean(row.responseSnippet), link: row.link }),
    renderCell: ({ value }) => (
      <SafeLeaseButton
        variant={value.requiresResponse ? "contained" : "text"}
        color="navy"
        endIcon={<KeyboardArrowRight sx={{ height: 16 }} />}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          window.open(value.link, "_blank");
        }}
      >
        {value.requiresResponse ? "Reply to review" : "See review"}
      </SafeLeaseButton>
    ),
  },
];
