import { Box, Slider, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Formatters } from "../../utilities/formatters";

interface PricingReportCoveragePlanSliderProps {

  /* For the label */
  coverage: number;

  /* What the premium is today */
  existingValue: number;

  /* Slider / premium controls */
  value: number;
  setValue: (value: number) => void;

  /* min/max for slider */
  min: number;
  max: number;

  /* To put a mark at the existing plan premium */
  marks?: boolean | any[];
  
}

export function PricingReportCoveragePlanSlider({
  coverage,
  value,
  existingValue,
  setValue,
  min,
  max,
  marks = true,
}: PricingReportCoveragePlanSliderProps) {

  const getSliderColor = () => {
    if (value < existingValue) return "error"
    if (value === existingValue) return "primary"
    return "success"
  }

  return (
    <Box sx={{ p: 2, backgroundColor: "rgba(0,0,0,0.03)", borderRadius: 2 }}>
      <Typography variant="caption" sx={{ color: grey[700] }}>
        ${Formatters.formatWithCommas(coverage / 100)} Plan
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Slider
          value={value}
          onChange={(_, value: number) => setValue(value)}
          min={min}
          max={max}
          getAriaValueText={(value) => Formatters.penniesToDollars(value)}
          valueLabelFormat={(value) => Formatters.penniesToDollars(value)}
          marks={marks}
          step={100}
          color={getSliderColor()}
        />
        <Box
          sx={{
            height: 80,
            width: 80,
            backgroundColor: "rgba(0,0,0,0.04)",
            borderRadius: 2,
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {Formatters.penniesToDollars(value)}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
