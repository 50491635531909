import { Stack } from "@mui/system";
import { FeedbackResponseActionArea, FeedbackResponseProps } from "./FeedbackResponseActionArea";
import { Theme, Typography } from "@mui/material";

export function FeedbackResponseFooter({ feedback }: FeedbackResponseProps) {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      {/* {feedback.message?.campaign && (
        <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
          {`${feedback.contact?.name ? `${feedback.contact.name}` : ""}`}
        </Typography>
      )}
      {!feedback.message?.campaign && (
        <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
          {feedback.contact?.name}
        </Typography>
      )} */}
      <FeedbackResponseActionArea feedback={feedback} />
    </Stack>
  );
}
