import { Fragment, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryResult, useApolloClient } from '@apollo/client';
import BillingDataService from '../services/billing.service';
import {
  getBillingEntities,
  getInvoiceLineItems,
  getInvoices,
  getLocations,
  getRecurringInvoiceLineItems,
  getRelationship,
  getRelationshipsSelector,
} from '../queries';
import { useAuth } from '../auth';
import FileSaver from 'file-saver';
import Button from '@mui/material/Button';
import { ghostButtonNavy, muiRedButton, muiSelect } from '../styles/mui-overrides';
import { invoiceStatuses } from '../utilities/invoice-statuses';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  BillingEntity,
  Invoice,
  InvoiceLineItem,
  Location,
  Relationship,
  RecurringInvoiceLineItem,
} from '../utilities/generated/gql-types';
import { Formatters } from '../utilities/formatters';
import _, { Dictionary } from 'lodash';
import DatePicker from '../components/lib/SafeLeaseDatePicker';
import dayjs from 'dayjs';
import { ProgramRevInvoiceLineItemUpdateModal } from "./ProgramRevInvoiceLineItemUpdateModal";

export function Invoices(props) {
  const params: { relationshipId: string } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const client = useApolloClient();
  const history = useHistory();
  const auth = useAuth();
  const isSuperAdmin = auth.user.role === 'superAdmin';
  const isAdmin = auth.user.isAdmin;

  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceLineItemModalOpen, setInvoiceLineItemModalOpen] = useState<boolean>(false);
  const [servicePeriodModalOpen, setServicePeriodModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [billingEntityId, setBillingEntityId] = useState<string>(
    searchParams.get('billingEntityId')
  );
  const [invoiceId, setInvoiceId] = useState<string>(searchParams.get('invoiceId'));
  const [invoiceLineItem, setInvoiceLineItem] = useState<InvoiceLineItem | null>(null);
  const [programRevInvoiceLineItemUpdateModalOpen, setProgramRevInvoiceLineItemUpdateModalOpen] = useState<boolean>(false);

  const getRelationshipsQuery: QueryResult<{ getRelationshipsSelector: Relationship[] }> = useQuery(getRelationshipsSelector);
  
  const relationshipQuery: QueryResult<{ getRelationship: Relationship }> = useQuery(
    getRelationship,
    {
      variables: { id: params.relationshipId },
      skip: !isAdmin,
    }
  );

  const billingEntitiesQuery: QueryResult<{ getBillingEntities: BillingEntity[] }> = useQuery(
    getBillingEntities,
    {
      variables: { relationshipId: params.relationshipId },
      skip: !isAdmin,
    }
  );

  const invoicesQuery: QueryResult<{ getInvoices: Invoice[] }> = useQuery(getInvoices, {
    variables: { billingEntityId },
    skip: !isAdmin || !billingEntityId,
  });

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery(getLocations, {
    variables: { relationshipId: params.relationshipId },
    skip: !isAdmin,
  });

  const invoiceLineItemsQuery: QueryResult<{ getInvoiceLineItems: InvoiceLineItem[] }> = useQuery(
    getInvoiceLineItems,
    {
      variables: { invoiceId },
      skip: !isAdmin || !billingEntityId || !invoiceId,
    }
  );

  const recurringInvoiceLineItemsQuery: QueryResult<{ getRecurringInvoiceLineItems: RecurringInvoiceLineItem[] }> = useQuery(
    getRecurringInvoiceLineItems,
    {
      variables: { billingEntityId },
      skip: !isAdmin || !billingEntityId,
    }
  );

  if (
    relationshipQuery.error ||
    billingEntitiesQuery.error ||
    invoicesQuery.error ||
    locationsQuery.error ||
    invoiceLineItemsQuery.error ||
    recurringInvoiceLineItemsQuery.error
  ) {
    return <>Error fetching data from server.</>;
  }

  if (
    relationshipQuery.loading ||
    billingEntitiesQuery.loading ||
    invoicesQuery.loading ||
    locationsQuery.loading ||
    invoiceLineItemsQuery.loading ||
    recurringInvoiceLineItemsQuery.loading
  ) {
    return <>Loading....</>;
  }

  if (
    (invoiceId && !billingEntityId) ||
    (billingEntityId &&
      billingEntitiesQuery?.data?.getBillingEntities &&
      !billingEntitiesQuery.data.getBillingEntities.find(
        (billingEntity) => billingEntity.id == billingEntityId
      ))
  ) {
    history.push(`/admin/invoices/${params.relationshipId}`);
    setBillingEntityId(null);
    setInvoiceId(null);
  }

  const promptUserForReason = async () => {
    const reason = window.prompt('Please enter a reason.');
    if (!reason) {
      setAlertMessage({
        message: 'You must enter a reason.',
        severity: 'error',
      });
    }

    return reason;
  };

  const handleCreateInvoiceClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCreateInvoiceClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const createInvoice = async (servicePeriod = null) => {
    try {
      setAlertMessage(null);
      const response = await BillingDataService.createInvoice(
        parseInt(billingEntityId),
        servicePeriod
      );

      if (response.data.invoice.id)
        history.push(
          `/admin/invoices/${params.relationshipId}?${new URLSearchParams({
            billingEntityId,
            invoiceId: response.data.invoice.id.toString(),
          })}`
        );
      setInvoiceId(response.data.invoice.id.toString());
      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to create an Ad-Hoc Invoice.',
        severity: 'error',
      });
    }
  };

  const saveInvoiceLineItem = async (invoiceLineItem) => {
    try {
      setAlertMessage(null);
      await BillingDataService.saveInvoiceLineItem(invoiceLineItem);

      client.refetchQueries({ include: [getInvoices, getInvoiceLineItems] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to save the invoices line item',
        severity: 'error',
      });
    }
  };

  const deleteInvoiceLineItem = async (invoiceLineItemId) => {
    try {
      setAlertMessage(null);
      await BillingDataService.deleteInvoiceLineItem(invoiceLineItemId);

      client.refetchQueries({ include: [getInvoices, getInvoiceLineItems] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to delete the invoices line item.',
        severity: 'error',
      });
    }
  };

  const downloadInvoicePdf = async () => {
    try {
      setAlertMessage(null);
      const response = await BillingDataService.invoicePdfUrl(parseInt(invoiceId));
      FileSaver.saveAs(response.data.url);
    } catch (e) {
      setAlertMessage({
        message: 'Failed to download the invoice pdf.',
        severity: 'error',
      });
    }
  };

  const toggleConfirmInvoice = async () => {
    if (
      !(
        !invoice.confirmed ||
        (invoice.confirmed &&
          window.confirm(
            'By unconfirming the invoice, the PDF will be deleted. Are you sure you want to do this?'
          ))
      )
    )
      return;
    try {
      setAlertMessage(null);
      setLoading(true);
      await BillingDataService.confirmInvoice(parseInt(invoiceId), !invoice.confirmed);

      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: e.response?.data ?? 'Failed to confirm the invoice.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const markInvoiceAsProcessed = async () => {
    const reason = await promptUserForReason();
    if (!reason) return;

    try {
      setAlertMessage(null);
      setLoading(true);
      await BillingDataService.markInvoiceAsProcessed(parseInt(invoiceId), reason);

      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to mark the invoice as paid.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteInvoice = async () => {
    if (!window.confirm('Are you sure you want to delete this invoice?')) return;

    try {
      setAlertMessage(null);
      setLoading(true);
      await BillingDataService.delete(parseInt(invoiceId));

      history.push(
        `/admin/invoices/${params.relationshipId}?${new URLSearchParams({
          billingEntityId,
        })}`
      );
      setInvoiceId(null);
      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to delete the invoice.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const resetInvoice = async () => {
    const reason = await promptUserForReason();
    if (!reason) return;

    try {
      setAlertMessage(null);
      setLoading(true);
      await BillingDataService.reset(parseInt(invoiceId), reason);

      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: 'Failed to reset the invoice.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateInvoicePausedStatus = async () => {
    if (!invoice.confirmed && invoice.paused) {
      if (
        !window.confirm(
          "Are you sure you want to unpause this invoice before it is confirmed?\n\nThis will enable automations for this invoice which could result in line items being modified from the current state of the invoice.",
        )
      )
        return;
    }
    try {
      setAlertMessage(null);
      setLoading(true);
      await BillingDataService.updatePausedValueForInvoice(parseInt(invoiceId), !invoice.paused);

      client.refetchQueries({ include: [getInvoices] });
    } catch (e) {
      setAlertMessage({
        message: "Failed to toggle pause value for the invoice.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const locationIdsToInvoiceLineItems: Dictionary<InvoiceLineItem[]> = _.groupBy(
    invoiceLineItemsQuery?.data?.getInvoiceLineItems,
    'locationId'
  );

  const invoice = invoiceId
    ? invoicesQuery?.data?.getInvoices?.find((invoice) => invoice.id == invoiceId)
    : null;

  const locations = billingEntityId
    ? locationsQuery?.data?.getLocations?.filter(
        (location) => location.billingEntityId == parseInt(billingEntityId)
      )
    : [];

  return (
    <>
      <div className='tw-flex tw-items-center'>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={params.relationshipId}
            displayEmpty
            onChange={e => history.push(`/admin/invoices/${e.target.value}`)}
            sx={muiSelect}
          >
            {getRelationshipsQuery.data?.getRelationshipsSelector?.map(relationship => {
              return (
                <MenuItem key={relationship.id} value={relationship.id}>
                  {relationship.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
          <InputLabel id="billing-entity-select-label">Billing Entity</InputLabel>
          <Select
            labelId="billing-entity-select-label"
            value={billingEntityId}
            onChange={(event) => {
              setAlertMessage(null);
              history.push(
                `/admin/invoices/${params.relationshipId}?${new URLSearchParams({
                  billingEntityId: event.target.value,
                })}`
              );
              setBillingEntityId(event.target.value);
            }}
          >
            {billingEntitiesQuery?.data?.getBillingEntities?.map((billingEntity) => {
              return <MenuItem key={billingEntity.id} value={billingEntity.id}>{billingEntity.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {billingEntityId && (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="invoice-select-label">Invoice</InputLabel>
            <Select
              labelId="invoice-select-label"
              value={invoiceId}
              onChange={(event) => {
                setAlertMessage(null);
                history.push(
                  `/admin/invoices/${params.relationshipId}?${new URLSearchParams({
                    billingEntityId,
                    invoiceId: event.target.value,
                  })}`
                );
                setInvoiceId(event.target.value);
                client.refetchQueries({ include: [getInvoices] }); // NOTE adding getInvoiceLineItems here causes results to sometimes be incorrect when switching invoices
              }}
            >
              {invoicesQuery?.data?.getInvoices?.map((invoice) => {
                return (
                  <MenuItem key={invoice.id} value={invoice.id}>{`${
                    invoice.servicePeriod || `${invoice.createdAt} Ad-Hoc #${invoice.id}`
                  } (${invoiceStatuses[invoice.status].label})`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}

        {billingEntityId && (
          <div className="tw-flex tw-w-full tw-justify-end tw-items-center">
            {invoice && (
              <>
                {isSuperAdmin && !invoice.confirmed && invoice.status === 'unsent' && (
                  <Button onClick={() => deleteInvoice()}>
                    <DeleteIcon />
                  </Button>
                )}
                {isSuperAdmin && invoice.confirmed && ['queued', 'failed', 'cancelled'].includes(invoice.status) && (
                  <Button onClick={() => resetInvoice()}>
                    <RestartAltIcon />
                  </Button>
                )}
                {invoice.paused && (
                  <Alert className="tw-ml-4" severity="warning">
                    Paused
                  </Alert>
                )}
                <Alert className="tw-ml-4" severity={invoice.confirmed ? 'success' : 'error'}>
                  {invoice.confirmed ? 'Confirmed' : 'Unconfirmed'}
                </Alert>
                <Alert className="tw-ml-4" severity={invoiceStatuses[invoice.status].severity}>
                  {invoiceStatuses[invoice.status].label}
                </Alert>
              </>
            )}

            <Button
              onClick={handleCreateInvoiceClick}
              sx={{ ...ghostButtonNavy, marginLeft: '1rem' }}
            >
              Create Invoice
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCreateInvoiceClose}>
              <MenuItem
                onClick={() => setServicePeriodModalOpen(true)}
                key={'monthly'}
                sx={{
                  '&.Mui-focusVisible': {
                    backgroundColor: 'white',
                  },
                  '&:hover': {
                    backgroundColor: '#2879FB',
                    color: 'white',
                  },
                }}
              >
                Create Monthly Invoice
              </MenuItem>
              <MenuItem
                onClick={() => createInvoice()}
                key={'ad-hoc'}
                sx={{
                  '&.Mui-focusVisible': {
                    backgroundColor: 'white',
                  },
                  '&:hover': {
                    backgroundColor: '#2879FB',
                    color: 'white',
                  },
                }}
              >
                Create Ad-Hoc Invoice
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {invoice && (
        <>
          <TableContainer component={Paper} className="tw-mt-4">
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell sx={{ minWidth: '300px' }}>Line Item</TableCell>
                  <TableCell>Amount Due to SafeLease</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(locationIdsToInvoiceLineItems).map((locationId) => {
                  const invoiceLineItems = locationIdsToInvoiceLineItems[locationId];

                  return (
                    <Fragment key={locationId}>
                      <TableRow
                        sx={{
                          backgroundColor: '#c9daf8',
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {invoiceLineItems[0].locationFullAddress}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell align="right">
                          {Formatters.penniesToDollars(
                            _.sumBy(invoiceLineItems, (invoiceLineItem) => invoiceLineItem.amount)
                          )}
                        </TableCell>
                      </TableRow>
                      {invoiceLineItems.map((invoiceLineItem) => {
                        return (
                          <TableRow
                            key={invoiceLineItem.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell />
                            <TableCell align="center">
                            {invoiceLineItem.recurringInvoiceLineItemId ? null : <Button
                                onClick={() => {
                                  setInvoiceLineItemModalOpen(true);
                                  setInvoiceLineItem(invoiceLineItem);
                                }}
                                disabled={invoice.confirmed}
                              >
                                <EditIcon />
                              </Button>}
                            </TableCell>
                            <TableCell align="left">{
                              invoiceLineItem.recurringInvoiceLineItemId ? (<i><b>(Recurring) {invoiceLineItem.label}</b></i>) :
                              invoiceLineItem.label
                            }</TableCell>
                            <TableCell align="right">
                              {Formatters.penniesToDollars(invoiceLineItem.amount)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Fragment>
                  );
                })}
                <TableRow
                  key={invoice.id}
                  sx={{
                    backgroundColor: '#1876d2',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: 'white', fontWeight: 'bold', fontSize: 'large' }}
                  >
                    Invoice Balance
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell
                    align="right"
                    sx={{ color: 'white', fontWeight: 'bold', fontSize: 'large' }}
                  >
                    {Formatters.penniesToDollars(invoice.balance)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="tw-flex tw-w-full tw-justify-end tw-items-center">
            {invoice.attachmentId && (
              <Button
                onClick={() => downloadInvoicePdf()}
                sx={{ marginTop: '1rem', marginLeft: '1rem' }}
              >
                <DownloadIcon />
              </Button>
            )}
            {loading ? (
              <CircularProgress sx={{ marginTop: '1rem', marginLeft: '1rem' }} />
            ) : (
              <>
                {invoice.servicePeriod && (
                  <Button
                    onClick={(event) => {
                      client.refetchQueries({ include: [getRecurringInvoiceLineItems] });
                      history.push(
                        `/admin/invoices/recurring_invoice_line_items/${params.relationshipId}?${new URLSearchParams({
                          billingEntityId,
                          invoiceId,
                        })}`
                      );
                    }}
                    sx={{ ...ghostButtonNavy, marginTop: '1rem', marginLeft: '1rem' }}
                  >
                    Manage Recurring Line Items
                  </Button>
                )}
                {!invoice.confirmed && (
                  <Button
                    onClick={() => setInvoiceLineItemModalOpen(true)}
                    sx={{ ...ghostButtonNavy, marginTop: '1rem', marginLeft: '1rem' }}
                  >
                    Add Line Item
                  </Button>
                )}
                {isSuperAdmin &&
                  invoice.confirmed &&
                  invoice.status === 'unsent' &&
                  billingEntitiesQuery?.data?.getBillingEntities?.find(
                    (billingEntity) => billingEntity.id == billingEntityId
                  )?.billingMethod !== 'ach' && (
                    <Button
                      onClick={() => markInvoiceAsProcessed()}
                      sx={{
                        ...muiRedButton({}),
                        marginTop: '1rem',
                        marginLeft: '1rem',
                        marginBottom: '0rem',
                      }}
                    >
                      Mark as Paid
                    </Button>
                  )}
                <Button
                  onClick={() => toggleConfirmInvoice()}
                  sx={
                    invoice.confirmed
                      ? { ...ghostButtonNavy, marginTop: '1rem', marginLeft: '1rem' }
                      : {
                          ...muiRedButton({}),
                          marginTop: '1rem',
                          marginLeft: '1rem',
                          marginBottom: '0rem',
                        }
                  }
                >
                  {invoice.confirmed ? 'Unconfirm' : 'Confirm'}
                </Button>
                {isSuperAdmin && (
                  <Button
                    variant="contained"
                    disableElevation
                    color={invoice.paused ? "inherit" : "warning"}
                    onClick={() => updateInvoicePausedStatus()}
                    sx={
                      invoice.paused
                        ? { ...ghostButtonNavy, marginTop: "1rem", marginLeft: "1rem" }
                        : {
                            marginTop: "1rem",
                            marginLeft: "1rem",
                            textTransform: "none",
                          }
                    }
                  >
                    {invoice.paused ? "Unpause" : "Pause"}
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="tw-flex tw-w-full tw-justify-end tw-items-center tw-mt-4">
            <Button
              variant="contained"
              disableElevation
              disabled={invoice.confirmed}
              onClick={() => setProgramRevInvoiceLineItemUpdateModalOpen(true)}
              sx={{ textTransform: "none" }}
            >
              Update invoice line items from program revenue amounts
            </Button>
          </div>
          {programRevInvoiceLineItemUpdateModalOpen && (
            <ProgramRevInvoiceLineItemUpdateModal
              locations={locations}
              setModalOpen={setProgramRevInvoiceLineItemUpdateModalOpen}
              save={(invoiceLineItem) => saveInvoiceLineItem(invoiceLineItem)}
              invoiceId={invoiceId}
              locationIdsToInvoiceLineItems={locationIdsToInvoiceLineItems}
            />
          )}
        </>
      )}

      {alertMessage && (
        <div className="tw-flex tw-justify-center">
          <Alert className="tw-mt-5" severity={alertMessage.severity}>
            {alertMessage.message}
          </Alert>
        </div>
      )}

      {invoiceLineItemModalOpen && (
        <InvoiceLineItemModal
          locations={locations}
          invoiceLineItem={invoiceLineItem}
          invoiceId={invoiceId}
          destroy={(invoiceLineItemId) => deleteInvoiceLineItem(invoiceLineItemId)}
          save={(invoiceLineItem) => saveInvoiceLineItem(invoiceLineItem)}
          close={() => {
            setInvoiceLineItemModalOpen(false);
            setInvoiceLineItem(null);
          }}
        />
      )}

      {servicePeriodModalOpen && (
        <ServicePeriodModal
          save={(servicePeriod) => {
            createInvoice(servicePeriod);
            setServicePeriodModalOpen(false);
          }}
          close={() => setServicePeriodModalOpen(false)}
        />
      )}
    </>
  );
}

function InvoiceLineItemModal(props) {
  const { locations, invoiceLineItem, invoiceId, destroy, save, close } = props;

  const [open, setOpen] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [locationId, setLocationId] = useState<number | string>(invoiceLineItem?.locationId);
  const [type, setType] = useState<string>(invoiceLineItem?.type || 'Tenant Protection - Premium');
  const [description, setDescription] = useState<string>(invoiceLineItem?.description);
  const [amount, setAmount] = useState<number>(invoiceLineItem?.amount / 100);

  const saveAndClose = async () => {
    if (!locationId || !type || !amount) {
      setError('Location, Type, and Amount are required');
      return;
    }
    save({
      id: invoiceLineItem?.id,
      invoiceId,
      locationId,
      type,
      description,
      amount: Math.trunc(amount * 100),
    });
    onClose();
  };

  const deleteAndClose = async () => {
    if (!window.confirm('Are you sure you want to delete this invoice line item? If it exists, the associated recurring line item will also be deleted.')) return;
    destroy(invoiceLineItem.id);
    onClose();
  };

  const onClose = async () => {
    setOpen(false);
    close();
  };
  
  const invoiceLineItemTypes = [
    'Tenant Protection - Premium',
    'Tenant Protection - Services',
    'Credit',
    'Other'
  ];

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="popup-modal"
    >
      <Box className="modal-box">
        <div className="modal-fields">
          <FormControl variant="standard" sx={{ width: 280 }}>
            <InputLabel id="location-select-label">Location</InputLabel>
            <Select
              labelId="location-select-label"
              value={locationId}
              onChange={(event: SelectChangeEvent) => {
                setLocationId(event.target.value);
              }}
            >
              {locations.map((location) => {
                return <MenuItem key={location.id} value={location.id}>{location.fullAddress}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: '1rem', width: 280 }}>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              onChange={(event: SelectChangeEvent) => {
                setType(event.target.value);
              }}
            >
              {invoiceLineItemTypes.map((type) => {
                return <MenuItem key={type} value={type}>{type}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <TextField
            className="modal-input-field"
            name="description"
            label={'Description'}
            type="text"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            required={type === 'Other'}
          />
          <TextField
            className="modal-input-field"
            name="amount"
            label={'Amount'}
            type="number"
            value={amount}
            onChange={(event) => setAmount(parseFloat(event.target.value))}
            required
          />
        </div>

        <Box textAlign="center">
          {invoiceLineItem && (
            <Button
              sx={{ ...muiRedButton({}), marginRight: '1rem' }}
              onClick={() => deleteAndClose()}
            >
              Delete
            </Button>
          )}
          <Button sx={{ ...muiRedButton({ bg: '#0f2846' }) }} onClick={() => saveAndClose()}>
            Save
          </Button>
        </Box>
        <p style={{ marginTop: 20, color: 'red' }}>{error}</p>
      </Box>
    </Modal>
  );
}

function ServicePeriodModal(props) {
  const { save, close } = props;

  const lastMonth = (() => {
    const newDate = dayjs();
    return newDate.subtract(1, 'month').startOf('month').toDate();
  })();
  const [servicePeriod, setServicePeriod] = useState<Date>(lastMonth);
  const [open, setOpen] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const saveAndClose = async () => {
    if (!servicePeriod) {
      setError('Please provide a month');
      return;
    }
    save(servicePeriod);
    onClose();
  };

  const onClose = async () => {
    setOpen(false);
    close();
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="popup-modal"
    >
      <Box className="modal-box">
        <div className="modal-fields">
          <DatePicker
            value={servicePeriod}
            onChange={(newDate) => {
              const dayJsDate = dayjs(newDate);
              const firstDayOfMonth = dayJsDate.startOf('month');
              setServicePeriod(firstDayOfMonth.toDate());
            }}
            label="Service Month"
            views={['year', 'month']}
          />
        </div>

        <Box textAlign="center">
          <Button
            sx={{ ...muiRedButton({ bg: '#0f2846' }), width: '150px' }}
            onClick={() => saveAndClose()}
            disabled={servicePeriod == null || isNaN(servicePeriod.getTime())}
          >
            Create Invoice
          </Button>
        </Box>
        {error && <p style={{ marginTop: 20, color: 'red' }}>{error}</p>}
      </Box>
    </Modal>
  );
}
