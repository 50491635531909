import { QueryResult } from "@apollo/client";
import CustomToolbar from "../../../shared/custom-toolbar";
import SafeleaseDataGrid from "../../../shared/safelease-data-grid";
import { useAdminBillingStore } from "../useAdminBillingStore";
import InvoiceDetailsDrawer from "./InvoiceDetailsDrawer/InvoiceDetailsDrawer";
import { invoicesTableColumnDefs } from "./InvoicesTableColumnDefs";
import { Invoice } from "../../../utilities/generated/gql-types";
import { GridRowParams } from "@mui/x-data-grid-premium";

type PaymentProcessingTableProps = {
  invoicesQuery: QueryResult<{ getInvoices: Invoice[] }>;
};

function PaymentProcessingTable({ invoicesQuery }: PaymentProcessingTableProps) {
  const {
    visibleColumns,
    updateVisibleColumns,
    selectedInvoiceIds,
    updateSelectedInvoiceIds,
    filterModel,
    updateFilterModel,
    setSelectedInvoiceForDrawer,
  } = useAdminBillingStore();

  return (
    <>
      <SafeleaseDataGrid
        autosizeOnMount
        checkboxSelection
        disableRowSelectionOnClick
        columns={invoicesTableColumnDefs}
        rows={invoicesQuery.data?.getInvoices.map((inv) => ({
          ...inv,
          invoiceLink: `/admin/invoices/${inv.relationshipId}?${new URLSearchParams({
            billingEntityId: inv.billingEntityId?.toString(),
            invoiceId: inv.id?.toString(),
          })}`,
        }))}
        columnVisibilityModel={visibleColumns}
        onColumnVisibilityModelChange={(newModel) => updateVisibleColumns(newModel)}
        onRowClick={(params: GridRowParams<Invoice>) => {
          setSelectedInvoiceForDrawer(params.row);
        }}
        rowSelectionModel={selectedInvoiceIds}
        onRowSelectionModelChange={(newModel) => updateSelectedInvoiceIds(newModel)}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => updateFilterModel(newModel)}
        slots={{
          toolbar: () => <CustomToolbar fileNamePrefix="SafeLease_Billing_Table_Export" />,
        }}
      />
      <InvoiceDetailsDrawer invoicesQuery={invoicesQuery} />
    </>
  );
}

export { PaymentProcessingTable };
