import { Grid } from "@mui/material";
import { useState } from "react";
import { PlanUpgradesOverview } from "./PlanUpgradesOverview";
import { ScheduleUpgradeFormDialog, DialogType } from "./ScheduleUpgradeForm/ScheduleUpgradeFormDialog";

interface PlanUpgradesProps {}

export function PlanUpgrades({}: PlanUpgradesProps) {

  const [dialogType, setDialogType] = useState(null);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlanUpgradesOverview setDialogOpen={(type: DialogType) => setDialogType(type)} />
        <ScheduleUpgradeFormDialog type={dialogType} handleClose={() => setDialogType(null)} />
      </Grid>
    </Grid>
  );
}
