import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Stack, List, ListItemText, ListItem, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useReputation } from "../../../../../useReputation";
import { enqueueSnackbar } from "notistack";
import { Facility_Reputation } from "@safelease/service-utilities";

type Props = {
  onPlanChange: (isPremium: boolean) => void;
  onMonthlyFeeChange: (monthlyFee: string) => void;
  facility: any;
};

enum FacilityTier {
  PREMIUM = 'premium',
  FREE = 'free',
}

export default function PremiumActivation({ onPlanChange, facility, onMonthlyFeeChange}: Props) {
const { tier, monthlyFee: initialMonthlyFee } = facility;
const { SafeLeaseAPI, refresh, isPremium } = useReputation();
const [open, setOpen] = useState(false);
const [selectedPlan, setSelectedPlan] = useState<FacilityTier>(tier === FacilityTier.PREMIUM ? FacilityTier.PREMIUM : FacilityTier.FREE);
const [monthlyFee, setMonthlyFee] = useState(initialMonthlyFee);

  const handleOpen = (plan: FacilityTier) => {
    const shouldOpen = plan !== tier;
    setSelectedPlan(plan);
    setOpen(shouldOpen);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const handleConfirm = async () => {
    const isPremiumSelected = selectedPlan === FacilityTier.PREMIUM;
    const shouldContinue = !isPremiumSelected || monthlyFee;
    if (shouldContinue) {
      await handlePlanChange(isPremiumSelected);
      onMonthlyFeeChange(monthlyFee);
      handleClose();
    }
  };

  const handlePlanChange = async (premium: boolean) => {
    onPlanChange(premium);
    const updatedTier = premium ? FacilityTier.PREMIUM : FacilityTier.FREE;
    const fee = premium ? Number(monthlyFee) : 0;
    try {
      await SafeLeaseAPI.reputation.updateFacilityDetails(facility.id, {
        tier: updatedTier,
        monthlyFee: fee,
      } as Partial<Facility_Reputation>);
      enqueueSnackbar('Facility updated to ' + updatedTier + ' successfully', { variant: 'success' });
      refresh();
    } catch (error) {
      enqueueSnackbar('Error updating facility to ' + updatedTier, { variant: 'error' });
    }
  };



  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <PlanButton
          isCurrentPlan={tier === FacilityTier.PREMIUM}
          planType={FacilityTier.PREMIUM}
          onClick={() => handleOpen(FacilityTier.PREMIUM)}
          label="Premium Plan"
        />
        <PlanButton
          isCurrentPlan={tier === FacilityTier.FREE}
          planType={FacilityTier.FREE}
          onClick={() => handleOpen(FacilityTier.FREE)}
          label="Free Plan"
        />
      </Stack>

      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Confirm Plan Change</DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <DialogContentText noWrap>
            Are you sure you want to switch to the <strong>{selectedPlan} plan</strong>?
            </DialogContentText>
          {selectedPlan === FacilityTier.PREMIUM && (
            <Stack direction="column" spacing={2}>
              <DialogContentText>
              Switching to the <strong>premium</strong> plan will add the following features:
              </DialogContentText>
              <List>
                <ListItem>QR Code Referral</ListItem>
                <ListItem>Texting Contacts</ListItem>
                <ListItem>AI Review Suggestions</ListItem>
                <ListItem>Customizable Campaings</ListItem>
              </List>

                <TextField
                  name="monthlyFee"
                  value={monthlyFee}
                  onChange={(e) => {
                    // Validate input is a valid dollar amount with 2 decimal places
                    if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                      setMonthlyFee(e.target.value);
                    }
                  }}
                  placeholder={`$${(facility.monthlyFee / 100).toFixed(2)}`}
                  fullWidth
                  margin="normal"
                  label="Monthly Fee"
                  helperText="Enter monthly fee amount, Must include the dollars and cents (e.g. 120.00)"
                  />
            </Stack>
          )}
          {selectedPlan === FacilityTier.FREE && (
            <>
              <DialogContentText >
                Switching to the <strong>free</strong> plan allows only the following features:
              </DialogContentText>
              <List>
                <ListItem>
                  <ListItemText primary="Email Reviews" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Move In Email Campagin" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Move Out Email Campagin" />
                </ListItem>
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button variant="outlined" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary" autoFocus disabled={selectedPlan === FacilityTier.PREMIUM && !monthlyFee}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

// This button controls the look and function of the premium plan and free plan buttons
const PlanButton = ({ isCurrentPlan, label, onClick, planType }) => (
  <Tooltip title={isCurrentPlan ? 'This is your currently selected plan' : ''}>
    <span>
      <Button
        variant={isCurrentPlan ? 'contained' : 'outlined'}
        onClick={onClick}
        sx={{
          backgroundColor: isCurrentPlan ? 'primary.main' : 'inherit',
          color: isCurrentPlan ? 'white' : 'inherit',
          '&:hover': {
            backgroundColor: isCurrentPlan ? 'primary.dark' : 'inherit'
          },
          pointerEvents: isCurrentPlan ? 'none' : 'auto'
        }}
      >
        {label}
      </Button>
    </span>
  </Tooltip>
);