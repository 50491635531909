import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { SafeLeaseCard, SafeLeaseFormTextField } from "@safelease/components";
import { useState } from "react";
import { z } from "zod";
import { useReputation } from "../../../../../useReputation";
import { Facility_Reputation } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { reactQueryClient } from "../../../../../../utilities/reactQueryClent";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { mixpanelEventHandler } from "../../../../../../utilities/reactMixpanelHandler";
import { useAuth } from "../../../../../../auth";
import PremiumActivation from "./PremiumActivation";
import { Formatters } from "../../../../../../utilities/formatters";



export const FacilityBilling = () => {
  const [saving, setSaving] = useState(false);
  const { facility, SafeLeaseAPI, selectedRelationshipId, refresh, isPremium } = useReputation();
  const auth = useAuth();

  const dollarAmountSchema = z
    .string()
    .transform((value) => value.replace(/[$,]/g, ''))
    .refine((value) => /^\d+\.\d{2}$/.test(value), {
      message: 'Must be a valid dollar amount with exactly two decimal places'
    });

  const facilityBillingSchema = z.object({
    monthlyFee: dollarAmountSchema,
  });

  const facilityBillingForm = useForm({
    defaultValues: {
      monthlyFee:
        facility.monthlyFee === 0
          ? "0.00"
          : facility.monthlyFee !== null
          ? Formatters.penniesToDollars(facility.monthlyFee)
          : "",
    },
    resolver: zodResolver(facilityBillingSchema),
  });

  const handleSave = async (data: Partial<Facility_Reputation>) => {
    // Convert monthlyFee to cents if it's a string
    if (typeof data.monthlyFee === "string") {
      data.monthlyFee = Math.round(parseFloat(data.monthlyFee) );
    }

    const previousMonthlyFee = facility.monthlyFee ;

    setSaving(true);
    try {
      await SafeLeaseAPI.reputation.updateFacilityDetails(facility.id, data);
      await reactQueryClient.invalidateQueries({
        queryKey: ["facilityDetails", facility.id],
      });
      await mixpanelEventHandler("Facility Monthly Fee Updated", {
        relationshipId: selectedRelationshipId,
        facilityId: facility.id,
        facilityName: facility.companyName,
        previousMonthlyFee: previousMonthlyFee,
        newMonthlyFee: data.monthlyFee,
        userEmail: auth.user.email,
        userId: auth.user.id,
      });
      enqueueSnackbar("Billing updated successfully", { variant: "success" });
    } catch (error) {
      console.error("Error updating facility", error);
      enqueueSnackbar("Error updating facility", { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  const errors = facilityBillingForm.formState.errors;

  const handlePlanChange = (isPremium: boolean, fee: string) => {
    if (isPremium) {
      facilityBillingForm.setValue("monthlyFee", fee)
    } else {
      facilityBillingForm.setValue("monthlyFee", "$0.00");
    }
  };



  const handleMonthlyFeeChange = (monthlyFee: string) => {
    facilityBillingForm.setValue('monthlyFee', monthlyFee);
  };

  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="h6">Billing</Typography>
        </Stack>
        <PremiumActivation
          onPlanChange={(isPremium) => handlePlanChange(isPremium, facilityBillingForm.getValues('monthlyFee'))}
          onMonthlyFeeChange={handleMonthlyFeeChange}
          facility={facility}
        />
        <FormProvider {...facilityBillingForm}>
          <form
            onSubmit={(e) => {
              facilityBillingForm.handleSubmit(handleSave)(e);
            }}
          >
            <Stack direction={'column'} spacing={2}>
              <SafeLeaseFormTextField
                name="monthlyFee"
                label="Monthly charge"
                description="Fee that the facility pays for SafeLease Reputation on a monthly basis. Must be filled out for each facility."
                sx={{ width: '100%' }}
                error={!!errors.monthlyFee?.message}
                helperText={errors.monthlyFee?.message}
                disabled={!isPremium}
              />
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </SafeLeaseCard>
  );
};
