import { create } from "zustand";
import {
  createPlanUpgradeSettingsLocationsSlice,
  PlanUpgradeSettingsLocationsSlice,
} from "./usePlanUpgradeSettingsLocationsSlice";
import {
  createPlanUpgradeSettingsSKUsSlice,
  PlanUpgradeSettingsSKUsSlice,
} from "./usePlanUpgradeSettingsSKUsSlice";
import {
  createPlanUpgradeSettingsPlansSlice,
  PlanUpgradeSettingsPlansSlice,
} from "./usePlanUpgradeSettingsPlansSlice";
import {
  createPlanUpgradeSettingsEnforcementDateSlice,
  PlanUpgradeSettingsEnforcementDateSlice,
} from "./usePlanUpgradeSettingsEnforcementDateSlice";
import {
  createPlanUpgradeSettingsRelationshipSlice,
  PlanUpgradeSettingsRelationshipSlice,
} from "./usePlanUpgradeSettingsRelationshipSlice";
import {
  createPlanUpgradeSettingsPlanStepsSlice,
  PlanUpgradeSettingsPlanStepsSlice,
} from "./usePlanUpgradeSettingsPlanStepsSlice";
import {
  createPlanUpgradeSettingsQueueingSlice,
  PlanUpgradeSettingsQueueingSlice,
} from "./usePlanUpgradeSettingsQueueingSlice";
import {
  createPlanUpgradeSettingsButtonsSlice,
  PlanUpgradeSettingsButtonsSlice,
} from "./usePlanUpgradeSettingsButtonsSlice";
import {
  createPlanUpgradeSettingsPlanSlice,
  PlanUpgradeSettingsPlanSlice,
} from "./usePlanUpgradeSettingsPlanSlice";

export type PlanUpgradeSettingsStore = PlanUpgradeSettingsLocationsSlice &
  PlanUpgradeSettingsSKUsSlice &
  PlanUpgradeSettingsPlansSlice &
  PlanUpgradeSettingsEnforcementDateSlice &
  PlanUpgradeSettingsRelationshipSlice &
  PlanUpgradeSettingsPlanStepsSlice &
  PlanUpgradeSettingsQueueingSlice &
  PlanUpgradeSettingsButtonsSlice &
  PlanUpgradeSettingsPlanSlice;

export const usePlanUpgradeStore = create<PlanUpgradeSettingsStore>()(
  (...a) => ({
    ...createPlanUpgradeSettingsLocationsSlice(...a),
    ...createPlanUpgradeSettingsSKUsSlice(...a),
    ...createPlanUpgradeSettingsPlansSlice(...a),
    ...createPlanUpgradeSettingsEnforcementDateSlice(...a),
    ...createPlanUpgradeSettingsRelationshipSlice(...a),
    ...createPlanUpgradeSettingsPlanStepsSlice(...a),
    ...createPlanUpgradeSettingsQueueingSlice(...a),
    ...createPlanUpgradeSettingsButtonsSlice(...a),
    ...createPlanUpgradeSettingsPlanSlice(...a),
  })
);
