import { useQuery } from "@apollo/client";
import { getNotificationSettings } from "../../../../queries";
import UserDataService from "../../../../services/user.service";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SafeleaseDropdown } from "../../../../common";
import { mixpanelEventHandler } from "../../../../utilities/reactMixpanelHandler";
import Loader from "../../../../shared/Loader";
import Error from "../../../../shared/Error";
import { enqueueSnackbar } from "notistack";

export default function Notifications({ userId }: { userId: string }) {
  const { data, loading, error, refetch } = useQuery(getNotificationSettings, { variables: { userId }, fetchPolicy: "network-only" }); // The fetchPolicy is set to "network-only" to ensure that the data is always up to date and not potentially in a stale state in the cache.

  const updateSettingKeyNames = {
    summary: "summary",
    summaryFrequency: "summaryFrequency",
    claim: "claim",
    privatePolicySummary: "privatePolicySummary",
  };

  const handleUpdateSettings = async (value: boolean | string, key: string) => {
    const getMixpanelEventName = (key) => {
      if (key === updateSettingKeyNames.summary) return "Update Setting - Change Report Email Setting";
      if (key === updateSettingKeyNames.summaryFrequency) return "Update Setting - Change Report Email Frequency";
      if (key === updateSettingKeyNames.claim) return "Update Setting - Change Claim Email Setting";
      if (key === updateSettingKeyNames.privatePolicySummary) return "Update Setting - Change Private Policy Summary Email Setting";
    };
    mixpanelEventHandler(getMixpanelEventName(key));
    try {
      await UserDataService.updateEmailSettings({ [key]: value, userId });
    } catch (error) {
      enqueueSnackbar("Failed to update notification settings", { variant: "error" });
    } finally {
      refetch();
    }
  };

  if (error) return <Error />;
  if (loading) return <Loader />;

  return (
    <div className="settings">
      <FormGroup>
        <FormControlLabel
          label="Report Emails"
          control={
            <Switch
              checked={data.getNotificationSettings.emailSettings.summary}
              onChange={(e) => handleUpdateSettings(e.target.checked, updateSettingKeyNames.summary)}
            />
          }
        />
        {data.getNotificationSettings.emailSettings.summary && (
          <div className="tw-ml-2 tw-mt-2">
            <SafeleaseDropdown
              label="Report Frequency"
              defaultValue={data.getNotificationSettings.emailSettings.summaryFrequency || "monthly"}
              menuOptionList={["monthly", "weekly"]}
              onChange={(e) => handleUpdateSettings(e.target.value, updateSettingKeyNames.summaryFrequency)}
            />
          </div>
        )}
        <FormControlLabel
          label="Claim Emails"
          control={
            <Switch
              checked={data.getNotificationSettings?.emailSettings.claim}
              onChange={(e) => handleUpdateSettings(e.target.checked, updateSettingKeyNames.claim)}
            />
          }
        />
        <FormControlLabel
          label="Private Policy Emails"
          control={
            <Switch
              checked={data.getNotificationSettings?.emailSettings.privatePolicySummary}
              onChange={(e) => handleUpdateSettings(e.target.checked, updateSettingKeyNames.privatePolicySummary)}
            />
          }
        />
      </FormGroup>
    </div>
  );
}
