import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import { GoogleReviewCard } from "./GoogleReviewCard";
import { Review } from "@safelease/service-utilities";
import { useEffect } from "react";
import { useReputation } from "../../../../../useReputation";

interface TenantReviewsProps {
}


export function TenantReviews({ }: TenantReviewsProps) {
  const { pendingReviews, statuses, currentPage, setCurrentPage, totalPages, setTotalPages } = useReputation();

  const totalCount =  pendingReviews?.totalCount
  const reviews = pendingReviews?.reviews

  useEffect(() => {
    if (!totalCount) return;
    setTotalPages(Math.ceil(totalCount /  100));
  }, [totalCount]);

  const isLoading = statuses.pendingReviews === "loading";

  if (isLoading) {
    return <CircularProgress />;
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  if (!pendingReviews || totalCount === 0) {
    return (
      <Typography variant="body1" color="grey.A100">
        No reviews to respond to. You're all caught up!
      </Typography>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      {reviews.map((review: Review) => (
        <GoogleReviewCard review={review} key={review.id} />
      ))}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          showFirstButton
        showLastButton
      />
    </Stack>
  );
}
