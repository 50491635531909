import { StateCreator } from "zustand";


export interface PlanUpgradeSettingsPlanSliceState {
  selectedPlanId: string | null;
}

export interface PlanUpgradeSettingsPlanSlice extends PlanUpgradeSettingsPlanSliceState {
  setSelectedPlanId: (selectedPlanId: string | null) => void;
}

export const createPlanUpgradeSettingsPlanSlice: StateCreator<
  PlanUpgradeSettingsPlanSliceState & PlanUpgradeSettingsPlanSlice
> = (set) => ({
  selectedPlanId: null,

  setSelectedPlanId: (planId: string | null) => {
    set({ selectedPlanId: planId });
  },
});
