import { Button, Card, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { InvoiceLineItem } from "../../utilities/generated/gql-types";
import { useState } from "react";
import billingService from "../../services/billing.service";
import { enqueueSnackbar } from "notistack";
import { Formatters } from "../../utilities/formatters";
import { Dictionary } from "lodash";

type ProgramRevInvoiceLineItemUpdateModalProps = {
  setModalOpen: (open: boolean) => void;
  locations: any[];
  save: (invoiceLineItem: InvoiceLineItem) => void;
  invoiceId: string;
  locationIdsToInvoiceLineItems: Dictionary<InvoiceLineItem[]>;
};

function ProgramRevInvoiceLineItemUpdateModal({
  setModalOpen,
  locations,
  save,
  invoiceId,
  locationIdsToInvoiceLineItems,
}: ProgramRevInvoiceLineItemUpdateModalProps) {
  const [locationId, setLocationId] = useState<string>(locations.length === 1 ? locations[0].id.toString() : ""); // If the invoice only has one location tied to the billing entity, we can pre-select it
  const [programRevenueAmount, setProgramRevenueAmount] = useState<number>(0);
  const [calculatedLineItemAmounts, setCalculatedLineItemAmounts] = useState<
    { service: { amount: number; type: string }; premium: { amount: number; type: string } } | undefined
  >(undefined);

  const handleCalculateLineItemAmounts = async () => {
    if (!locationId) {
      enqueueSnackbar("Please select a location", { variant: "error" });
      return;
    }
    try {
      const response = await billingService.calculateLineItemAmountsFromProgramRevenue(programRevenueAmount * 100, parseInt(locationId));
      setCalculatedLineItemAmounts(response.data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error calculating line item amounts", { variant: "error" });
    }
  };

  const handleUpdateLineItems = async () => {
    if (!calculatedLineItemAmounts) {
      enqueueSnackbar("Please calculate line item amounts first before updating", { variant: "error" });
      return;
    }
    for (const lineItem of Object.values(calculatedLineItemAmounts)) {
      const existingLineItems: InvoiceLineItem[] | undefined = locationIdsToInvoiceLineItems[parseInt(locationId)];
      const existingLineItem = existingLineItems?.find((item) => item.type === lineItem.type);
      save({
        amount: lineItem.amount,
        type: lineItem.type,
        id: existingLineItem?.id,
        invoiceId: parseInt(invoiceId),
        locationId: parseInt(locationId),
      });
    }
    setModalOpen(false);
  };

  return (
    <Modal open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card>
        <Stack padding={2} spacing={2}>
          <Typography variant="h6">Update invoice line items from program revenue amounts</Typography>
          <Select
            labelId="location-select-label"
            value={locationId}
            onChange={(event) => {
              setLocationId(event.target.value);
            }}
          >
            {locations.map((location) => {
              return (
                <MenuItem key={location.id} value={location.id}>
                  {location.fullAddress}
                </MenuItem>
              );
            })}
          </Select>

          <>
            {calculatedLineItemAmounts && (
              <Stack spacing={2}>
                <Typography variant="body1">
                  {calculatedLineItemAmounts.premium.type}: {Formatters.penniesToDollars(calculatedLineItemAmounts?.premium.amount)}
                </Typography>
                <Typography variant="body1">
                  {calculatedLineItemAmounts.service.type}: {Formatters.penniesToDollars(calculatedLineItemAmounts?.service.amount)}
                </Typography>
              </Stack>
            )}
          </>
          <Stack direction="row" spacing={2}>
            <TextField
              disabled={locationId === ""}
              fullWidth
              label="Program revenue amount"
              type="number"
              value={programRevenueAmount || ""} // Return empty string when value is 0
              onChange={(event) => {
                const value = event.target.value;
                if (value === "") {
                  setProgramRevenueAmount(0);
                } else {
                  const parsed = parseFloat(value);
                  if (!isNaN(parsed)) {
                    setProgramRevenueAmount(parseFloat(parsed.toFixed(2)));
                  }
                }
              }}
            />
            <Button
              disabled={locationId === "" || !programRevenueAmount}
              variant="outlined"
              sx={{ textTransform: "none" }}
              disableElevation
              onClick={handleCalculateLineItemAmounts}
            >
              Calculate
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button variant="outlined" sx={{ textTransform: "none" }} disableElevation onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              disabled={!calculatedLineItemAmounts}
              color="error"
              variant="contained"
              sx={{ textTransform: "none" }}
              disableElevation
              onClick={handleUpdateLineItems}
            >
              Update line items
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { ProgramRevInvoiceLineItemUpdateModal };
