import { Box, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Formatters } from "../../../utilities/formatters";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckIcon from "@mui/icons-material/Check";
import { Close } from "@mui/icons-material";
import { InvoiceDataForReview } from "../../../utilities/generated/gql-types";

export const invoiceReviewTableColumnDefs: GridColDef<InvoiceDataForReview>[] = [
  {
    field: "locationId",
    headerName: "Location ID",
  },
  {
    field: "locationName",
    headerName: "Location Name",
  },
  {
    field: "relationshipName",
    headerName: "Relationship",
    flex: 1,
  },
  {
    field: "billingEntityName",
    headerName: "Billing Entity Name",
    flex: 1,
  },
  {
    field: "locationLiveDate",
    headerName: "Live Date",
  },
  {
    field: "locationCancelDate",
    headerName: "Cancel Date",
  },
  {
    field: "locationApAllDate",
    headerName: "Ap All Date",
  },
  {
    field: "locationRolloverDate", 
    headerName: "Rollover Date",
  },
  {
    field: "invoiceLink",
    headerName: "Invoice",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }: GridRenderCellParams) => {
      const link = `/admin/invoices/${row.relationshipId}?${new URLSearchParams({
        billingEntityId: row.billingEntityId?.toString(),
        ...(row.currentMonthInvoiceId && { invoiceId: row.currentMonthInvoiceId.toString() }),
      })}`;
      return (
        <Tooltip title="Open invoice in a new tab">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              window.open(link, "_blank");
            }}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },
  {
    field: "twoMonthsPriorAmountForLocationInvoiceLineItems",
    headerName: "2 months prior",
    valueFormatter: ({ value }) => Formatters.penniesToDollars(value),
  },
  {
    field: "oneMonthPriorAmountForLocationInvoiceLineItems",
    headerName: "1 month prior",
    valueFormatter: ({ value }) => Formatters.penniesToDollars(value),
  },
  {
    field: "amountDiffFromLastMonth",
    headerName: "Amount diff from prior month",
    valueGetter: ({ row }) => {
      return row.currentAmountForLocationInvoiceLineItems - row.oneMonthPriorAmountForLocationInvoiceLineItems;
    },
    valueFormatter: ({ value }) => Formatters.penniesToDollars(value),
  },
  {
    field: "percentageDiffFromLastMonth",
    headerName: "Percentage diff from prior month",
    valueGetter: ({ row }) => {
      if (row.oneMonthPriorAmountForLocationInvoiceLineItems === 0) {
        // To avoid division by zero
        if (row.currentAmountForLocationInvoiceLineItems > 0) {
          return 100;
        }
        return 0;
      }
      return (
        ((row.currentAmountForLocationInvoiceLineItems - row.oneMonthPriorAmountForLocationInvoiceLineItems) /
          row.oneMonthPriorAmountForLocationInvoiceLineItems) *
        100
      );
    },
    valueFormatter: ({ value }) => `${value.toFixed(2)}%`,
    renderCell: ({ value }) => {
      let backgroundColor = "#f5f5f5"; // light grey default
      const absValue = Math.abs(value);

      if (absValue > 20) {
        backgroundColor = value > 0 ? "#e8f5e9" : "#ffebee"; // light green : light red
      }

      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor,
          }}
        >
          {value.toFixed(2)}%
        </Box>
      );
    },
  },
  {
    field: "currentAmountForLocationInvoiceLineItems",
    headerName: "Amount",
    valueFormatter: ({ value }) => Formatters.penniesToDollars(value),
  },
  {
    field: "currentMonthInvoiceId",
    headerName: "Invoice ID",
  },
  {
    field: "confirmed",
    headerName: "Invoice Confirmed",
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />;
      } else {
        return <Close sx={{ color: (theme) => theme.palette.error.main }} />;
      }
    },
  },
  {
    field: "pausedStatus",
    headerName: "Invoice Paused",
    headerAlign: "center",
    align: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return <CheckIcon sx={{ color: (theme) => theme.palette.success.main }} />;
      } else {
        return <Close sx={{ color: (theme) => theme.palette.error.main }} />;
      }
    },
  },
];
