import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import { Formatters } from "../../../../utilities/formatters";

type InvoiceBalanceCardProps = {
  autoConfirmed: boolean;
  balanceAmount: number;
};

const InvoiceBalanceCard = ({ autoConfirmed, balanceAmount }: InvoiceBalanceCardProps) => {
  return (
    <Card
      elevation={0}
      sx={{
        background: autoConfirmed ? "rgba(37, 155, 36, 0.1)" : "rgba(233, 100, 95, 0.1)",
      }}
    >
      <Stack direction="row" padding="15px" spacing={4} alignItems="center">
        <Typography variant="h6" color="rgba(21, 39, 68, 1)">
          Invoice Balance
        </Typography>
        <Chip
          size="small"
          sx={{ color: "white", background: autoConfirmed ? "rgba(37, 155, 36, 1)" : "rgba(233, 100, 95, 1)" }}
          label={autoConfirmed ? "CONFIRMED" : "UNCONFIRMED"}
        />
      </Stack>
      <Box sx={{ padding: "15px" }}>
        <Typography
          align="right"
          variant="h3"
          sx={{ fontWeight: 600, color: autoConfirmed ? "rgba(37, 155, 36, 1)" : "rgba(233, 100, 95, 1)" }}
        >
          {Formatters.penniesToDollars(balanceAmount)}
        </Typography>
      </Box>
    </Card>
  );
};

export default InvoiceBalanceCard;
