import { Grid, IconButton, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";

type FilePreviewsProps = {
  files: (File | Attachment_ClaimsDbModel)[];
  deleteFile?: (file: File | Attachment_ClaimsDbModel) => void;
};

function FilePreviews({ files, deleteFile }: FilePreviewsProps) {
  return (
    <Grid sx={{ overflowX: "auto", display: "flex", flexDirection: "column" }}>
      {files.map((file) => {
        const key = file instanceof File ? file.name + file.lastModified : file.id;
        const name = file instanceof File ? file.name : file.fileName;
        const size = file instanceof File ? file.size : file.fileSize;

        return (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: 4,
            }}
            key={key}
          >
            <Grid item xs={1}>
              <InsertDriveFileIcon sx={{ color: "grey" }} />
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: "600", fontSize: ".9rem" }}>{name}</Typography>
              <Typography sx={{ fontWeight: "400", fontSize: ".7rem", color: size > 1024 * 1024 * 6 ? "red" : "grey" }}>
                {size > 1024 * 1024 * 6 ? `File is too large. Please reduce to 6MB or less.Size: ${(size / (1024 * 1024)).toFixed(2)} MB` : `Size: ${size > 1024 * 1024 ? `${(size / (1024 * 1024)).toFixed(2)} MB` : `${(size / 1024).toFixed(2)} KB`}`}
              </Typography>
            </Grid>
            {deleteFile && (
              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    deleteFile(file);
                  }}
                >
                  <DeleteIcon sx={{ color: "grey" }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export { FilePreviews };
