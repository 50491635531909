import React from "react";
import TitleHeader from "../shared/title-header";
import { useEffect, useState } from "react";
import { useAuth } from "../auth.js";
import AutoProtect from "./components/AutoProtect";
import { Box } from "@mui/system";
import { Paper } from "@mui/material";
import BillingSettings from "./components/BillingSettings";
import LocationSettings from "./components/LocationSettings";
import { useHistory } from "react-router-dom";
import UserSettings from "./components/UserSettings";
import Contact from "./components/Contact";
import Organization from "./components/OrganizationSettings/Organization";
import { RelationshipJobs } from "./components/RelationshipJobs";
import TabPanel from "../shared/TabPanel";
import SafeleaseTab from "../shared/safelease-tab";
import SafeleaseTabs from "../shared/SafeleaseTabs";
import { PrivatePolicySettings } from "./components/PrivatePolicySettings";
import MyAccount from "./components/MyAccount/MyAccount";
import AccessControlled from "../components/AccessControlled";

const allTabs = [
  { label: "User", component: UserSettings, key: "userSettings" },
  { label: "Locations", component: LocationSettings, key: "locationSettings", isAdmin: true },
  { label: "Billing", component: BillingSettings, key: "billingSettings", isInsuranceUser: true, accessControlled: { type: 'Relationship', permission: 'viewBilling' } },
  { label: "Auto-Protect", component: AutoProtect, key: "autoProtectSettings", isAdminOrManager: true, isAutoProtect: true },
  { label: "Private Policy", component: PrivatePolicySettings, key: "privatePolicySettings", isAdmin: true },
  { label: "Jobs", component: RelationshipJobs, key: "jobSettings", isAdmin: true },
  { label: "Contact", component: Contact, key: "contactSettings", isInsuranceUser: true },
  { label: "Organization", component: Organization, key: "organizationSettings", featureFlag: 'organization-settings', accessControlled: { controlBy: 'feature', featureFlag: 'organization-settings' } },
  { label: "My Account", component: MyAccount, key: "myAccountSettings", featureFlag: 'my-account', accessControlled: { controlBy: 'feature', featureFlag: 'my-account' } },
];

function getAvailableTabs(routerHelper, isAdmin, isManager, isInsuranceUser) {
  return allTabs.filter(tab => {
    if (tab.isAdmin && !isAdmin) return false;
    if (tab.isAdminOrManager && !(isAdmin || isManager)) return false;
    if (tab.isInsuranceUser && !isInsuranceUser) return false;
    if (tab.isAutoProtect && !routerHelper.getIsAutoProtectUser()) return false;
    if (tab.featureFlag && !routerHelper.getFeatureFlags().includes(tab.featureFlag)) return false;
    return true;
  }).map((tab, index) => ({ ...tab, value: index }));
}

function Settings(props) {
  const { routerHelper, tab } = props;
  const auth = useAuth();
  const history = useHistory();

  const isAdmin = auth.user.isAdmin;
  const isManager = auth.user.role === "manager";
  const isInsuranceUser = routerHelper.getIsInsuranceUser();

  const availableTabs = getAvailableTabs(routerHelper, isAdmin, isManager, isInsuranceUser);
  const [currentTab, setCurrentTab] = useState(availableTabs.find(t => t.key === tab)?.value || 0);
  
  const handleChange = (event, newValue) => {
    const tabName = availableTabs.find(t => t.value === newValue)?.key;
    routerHelper.navigateToRoute(tabName, routerHelper.getRelationshipId(), history);
    setCurrentTab(newValue);
  };

  useEffect(() => {
    document.body.classList.add("settings");
    return () => {
      document.body.classList.remove("settings");
    };
  }, []);

  return (
    <div>
      <TitleHeader title="Settings" />
      <div className="widget widget__long">
        <Box>
          <SafeleaseTabs value={currentTab} onChange={handleChange} aria-label="settings tabs">
            {availableTabs.map(tab => {
              if(tab.accessControlled) {
                const { type, permission, controlBy, featureFlag } = tab.accessControlled;
                return (
                  <AccessControlled
                    key={tab.value} 
                    id={routerHelper.getRelationshipId()}
                    routerHelper={routerHelper}
                    type={type}
                    permission={permission}
                    controlBy={controlBy as "feature"}
                    featureFlag={featureFlag}
                  >
                    <SafeleaseTab value={tab.value} label={tab.label} />
                  </AccessControlled>
                )
              } else {
                return (
                  <SafeleaseTab key={tab.value} value={tab.value} label={tab.label} />
                )
              }
            })}
          </SafeleaseTabs>
        </Box>
        {availableTabs.map(tab => (
          <TabPanel key={tab.value} currentTab={currentTab} index={tab.value}>
            <div className="widget-content settings-content">
              <Paper sx={{ backgroundColor: "inherit", boxShadow: "none", width: "100%" }}>
                {React.createElement(tab.component, { routerHelper })}
              </Paper>
            </div>
          </TabPanel>
        ))}
      </div>
    </div>
  );
}

export default Settings;