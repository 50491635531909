import { Box, Button, Divider, Drawer, Stack, Switch, Typography } from "@mui/material";
import { usePrivatePolicySettingsStore } from "../../usePrivatePolicySettingsStore/usePrivatePolicySettingsStore";
import { inverseNavyButton, navyButton } from "../../../../../styles/mui-overrides";
import LocationSelector from "../../../../../shared/LocationSelector";
import { PrivatePolicyComplianceConfig } from "../../../../../utilities/generated/gql-types";
import InternalEnforcementOptions from "./InternalEnforcementOptions";
import FmsEnforcementOptions from "./FmsEnforcementOptions";

type PrivatePolicyComplianceDrawerMenuProps = {
  handleClose: () => void;
  handleApply: () => void;
  relationshipId: string; // used for location selector
  privatePolicyConfigs: PrivatePolicyComplianceConfig[]; // the list of private policy configs for the selected relationship. Used to populate the title of the drawer based on which location is selected
};

function PrivatePolicyComplianceDrawerMenu({
  handleClose,
  handleApply,
  relationshipId,
  privatePolicyConfigs
}: PrivatePolicyComplianceDrawerMenuProps) {
  const configMenuOpen = usePrivatePolicySettingsStore((state) => state.configMenuOpen);
  const internalEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.internalEnforcementEnabled);
  const setInternalEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.setInternalEnforcementEnabled);
  const fmsEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.fmsEnforcementEnabled);
  const setFmsEnforcementEnabled = usePrivatePolicySettingsStore((state) => state.setFmsEnforcementEnabled);
  const selectedLocationId = usePrivatePolicySettingsStore((state) => state.selectedLocationId);
  const setSelectedLocationId = usePrivatePolicySettingsStore((state) => state.setSelectedLocationId);
  const firstChargeNextBillingCycle = usePrivatePolicySettingsStore((state) => state.firstChargeNextBillingCycle);
  const setFirstChargeNextBillingCycle = usePrivatePolicySettingsStore((state) => state.setFirstChargeNextBillingCycle);
  const tenantPolicyExpirationRemindersEnabled = usePrivatePolicySettingsStore((state) => state.tenantPolicyExpirationRemindersEnabled);
  const setTenantPolicyExpirationRemindersEnabled = usePrivatePolicySettingsStore((state) => state.setTenantPolicyExpirationRemindersEnabled);

  return (
    <Drawer
      anchor="right"
      open={configMenuOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { width: "40%", minWidth: "450px", backgroundColor: "rgba(255, 255, 255, 1)" },
      }}
    >
      <Stack spacing={2} sx={{ padding: "32px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack>
            <Typography fontWeight="700" fontFamily="Open Sans, sans-serif" color="rgba(40, 121, 251, 1)">Private Policy Compliance Configuration</Typography>
            <Typography color="rgba(21, 39, 68, 1)" variant="h5">{privatePolicyConfigs.find((config) => (config.locationId) === selectedLocationId.toString())?.locationAddress || "All Locations"}</Typography>
          </Stack>
          <Box sx={{ display: "flex" }}>
            <LocationSelector
              locationId={selectedLocationId}
              setLocationId={setSelectedLocationId}
              relationshipId={relationshipId}
              width="140px"
            />
          </Box>
        </Box>
        <Divider variant="middle" />
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h6">FMS Private Policy Enforcements</Typography>
            <Switch
              checked={fmsEnforcementEnabled}
              onChange={() => setFmsEnforcementEnabled(!fmsEnforcementEnabled)}
            />
          </Stack>
          {
            fmsEnforcementEnabled && <FmsEnforcementOptions />
          }
        </Stack>
        <Divider variant="middle" />
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Internal Private Policy Enforcements</Typography>
            <Switch
              checked={internalEnforcementEnabled}
              onChange={() => setInternalEnforcementEnabled(!internalEnforcementEnabled)}
            />
          </Stack>
          {
            internalEnforcementEnabled && <InternalEnforcementOptions />
          }
        </Stack>
        <Divider variant="middle" />
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h6">First Charge Next Billing Cycle</Typography>
            <Switch
              checked={firstChargeNextBillingCycle}
              onChange={() => setFirstChargeNextBillingCycle(!firstChargeNextBillingCycle)}
            />
          </Stack>
        </Stack>
        <Divider variant="middle" />
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Tenant Policy Expiration Reminders</Typography>
            <Switch
              checked={tenantPolicyExpirationRemindersEnabled}
              onChange={() => setTenantPolicyExpirationRemindersEnabled(!tenantPolicyExpirationRemindersEnabled)}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} paddingTop="50px" justifyContent="flex-end">
          <Button
            disableElevation sx={{ ...inverseNavyButton, width: "224px" }}
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            sx={{ ...navyButton, width: "224px" }}
            variant="contained"
            onClick={handleApply}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export { PrivatePolicyComplianceDrawerMenu };
