import { useState } from 'react';
import RelationshipDataService from '../services/relationship.service';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function RelationshipEditModal(props) {
  const { relationship, onClose } = props;

  const [errors, setErrors] = useState<{
    name?: { msg?: string };
  }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(relationship?.name);

  const saveRelationship = async function () {
    if (!window.confirm('Are you sure you want to save the relationship?')) return;

    let relationshipClone = { ...relationship, name };

    setLoading(true);
    setErrors({});
    const response = await RelationshipDataService.addRelationship(relationshipClone);
    setLoading(false);

    if (response.data.errors) {
      setErrors(response.data.errors);
      setErrorMessage('A problem occurred while saving the relationship.');
      return;
    }

    onClose(response.data.id);
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="popup-modal"
    >
      <Box className="modal-box" style={{ width: 400, padding: 2 }}>
        <div className="tw-flex tw-justify-end">
          <IconButton>
            <CloseIcon onClick={() => onClose()} />
          </IconButton>
        </div>
        <div className="tw-flex tw-justify-center tw-pl-10 tw-pr-10">
          <strong>{relationship?.id ? `${relationship.name} (SafeLease ID: ${relationship.id})` : 'Create a Relationship'}</strong>
        </div>

        <Paper
            sx={{
              maxHeight: 600,
              overflow: 'auto',
              backgroundColor: 'inherit',
              boxShadow: 'none',
              padding: 5,
            }}
          >
          <TextField
            name="name"
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ marginBottom: 1, width: '100%' }}
            helperText={errors.name?.msg}
          />
          <Box textAlign="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button className="modal-login-button" onClick={saveRelationship}>
                {relationship?.id ? 'Save' : 'Create'}
              </Button>
            )}
          </Box>

          {errorMessage && (
            <Box textAlign="center" marginTop="10px">
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
          </Paper>
      </Box>
    </Modal>
  );
}
