import { useMemo, useState } from "react";

// UI - libs
import { Drawer, Tooltip } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EditIcon from "@mui/icons-material/Edit";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import DeleteIcon from "@mui/icons-material/Delete";
import { GridRowParams, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";

// UI - internal
import UserSettingsDrawer from "../UserSettingsDrawer";
import SafeleaseDataGrid from "../../../../shared/safelease-data-grid";
import SelectLocationsDropdown from "./SelectLocationsDropdown";
import SelectBillingEntityDropdown from "./SelectBillingEntityDropdown";

// Data
import { QueryResult, useQuery } from "@apollo/client";
import UserDataService from "../../../../services/user.service";
import { getLlcs, getLocations, getUsers } from "../../../../queries";
import { Llc, Location, User } from "../../../../utilities/generated/gql-types";
import RouterHelper from "../../../../utilities/router-helper";
import { useAuth } from "../../../../auth";
import { useSnackbar } from "notistack";
import DeleteUserDialog from "../UserSettingsDrawer/DeleteUserDialog";
import { useUserManagementSettings } from "../useUserManagementSettings";
import { EditMode } from "../../../../shared/drawer-footer";
import { Roles } from "../../../../utilities/field-enums";

interface UsersTableProps {
  routerHelper: RouterHelper;
  searchValue: string;
}

export default function UsersTable({ routerHelper, searchValue }: UsersTableProps) {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { editDrawerOpenMode, setEditDrawerOpenMode, currentlyEditedUser, setCurrentlyEditedUser, closeDrawer } =
    useUserManagementSettings();

  const [pendingDeleteUser, setPendingDeleteUser] = useState<User | null>(null);

  const relationshipId = routerHelper.getRelationshipId() || auth.user.relationshipId;
  const isAdmin = auth.user?.isAdmin;

  const usersQuery: QueryResult<{ getUsers: { edges: User[] } }> = useQuery(getUsers, {
    variables: {
      relationshipId,
    },
  });

  const locationsQuery: QueryResult<{ getLocations: Location[] }> = useQuery<{
    getLocations: Location[];
  }>(getLocations, {
    variables: {
      relationshipId,
    },
  });

  const llcsQuery: QueryResult<{ getLlcs: Llc[] }> = useQuery(getLlcs, {
    variables: { relationshipId },
  });

  const users: User[] = usersQuery.data?.getUsers.edges || [];
  const locations: Location[] = locationsQuery.data?.getLocations || [];
  const llcs: Llc[] = llcsQuery.data?.getLlcs || [];

  const onUserActionsClick = (user: User | null, editMode: EditMode) => {
    setCurrentlyEditedUser(user);
    setEditDrawerOpenMode(editMode);
  };

  const resendInvitation = async (user) => {
    try {
      if (!window.confirm("Are you sure you want to resend the invitation?")) return;
      await UserDataService.resendInvitation(user);
      enqueueSnackbar("Invitation resent.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error resending invitation.", { variant: "error" });
    }
  };

  const userManagementColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "All users",
      width: 200,
      renderCell: (params) => <strong style={{ color: "rgba(3,30,48,1)", fontFamily: "Open Sans" }}>{params.value}</strong>,
    },
    {
      field: "pointOfContact",
      headerName: "Point of contact for:",
      width: 300,
      sortable: false,
      renderCell: (params) => <SelectLocationsDropdown locations={locations} value={params.value.locationIds || []} user={params.value} />,
    },
    {
      field: "llc",
      headerName: "Billing contact for:",
      width: 300,
      sortable: false,
      renderCell: (params) => <SelectBillingEntityDropdown llcs={llcs} value={params.value.llcs || []} user={params.value.user} />,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
    },
    {
      field: "actions",
      headerName: " ",
      type: "actions",
      width: auth.user?.role !== Roles.Employee ? 90 : 40, // only employees can't re-send invites
      align: "right",
      resizable: false,
      getActions: (params: GridRowParams) => {
        const actions = [];

        {
          /** If the currently authenticated user has the ability to resend invites, present a re-invite option */
        }
        if (isAdmin) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Re-send invite">
                  <ForwardToInboxIcon />
                </Tooltip>
              }
              label="Re-invite"
              onClick={() => resendInvitation(params.row.user)}
            />,
          );
        }

        {
          /** Option to open the editing menu for the user */
        }
        if (params.row.user.canUpdate) {
          actions.push(<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => onUserActionsClick(params.row.user, EditMode.Edit)} showInMenu />);
          actions.push(<GridActionsCellItem icon={<EditNotificationsIcon />} label="Notifications" onClick={() => onUserActionsClick(params.row.user, EditMode.Notifications)} showInMenu />)
        }

        {
          /** If the currently authenticated user has the ability to archive other users, present a delete option
           * This property is populated in graphql.ts `getUsers` function, and the value that gets returned is
           * relative to the authenticated user making the request (e.g. if the authenticated user has the permission to archive
           * user, then the `canArchive` property will be true, otherwise it will be false)
           */
        }
        if (params.row.user.canArchive) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteIcon sx={{ color: "error.main" }} />}
              label="Delete"
              sx={{ color: "error.main" }}
              onClick={() => setPendingDeleteUser(params.row.user)}
              showInMenu
            />,
          );
        }
        return actions;
      },
    },
  ];

  const data = useMemo(
    () =>
      users
        .map((user: User) => {
          return {
            id: user.id,
            name: user.name,
            role: user.role,
            user,
            pointOfContact: user,
            llc: {
              llcs,
              user, // #todo switch to using params.row.user instead
            },
          };
        })
        .filter((user) => {
          const { role, email, name } = user.user;

          if (searchValue.toLowerCase().startsWith("role:")) {
            const roleSearchTerm = searchValue.substring(5); // Remove "role:" prefix
            return role && role.toLowerCase().includes(roleSearchTerm.toLowerCase());
          } else if (searchValue.toLowerCase().startsWith("email:")) {
            const emailSearchTerm = searchValue.substring(6); // Remove "email:" prefix
            return email && email.toLowerCase().includes(emailSearchTerm.toLowerCase());
          } else {
            // Default search (name)
            return name && name.toLowerCase().includes(searchValue.toLowerCase());
          }
        }) || [],
    [usersQuery?.data, locationsQuery?.data, llcsQuery?.data, searchValue],
  );

  return (
    <div style={{ height: 420, fontFamily: "Open Sans" }}>
      <SafeleaseDataGrid columns={userManagementColumns} rows={data} initialState={{ pinnedColumns: { right: ["actions"] } }} />
      <Drawer anchor={"right"} open={editDrawerOpenMode !== null} onClose={closeDrawer}>
        <UserSettingsDrawer currentlyEditedUser={currentlyEditedUser} locations={locations} relationshipId={relationshipId} />
      </Drawer>
      <DeleteUserDialog
        user={pendingDeleteUser}
        open={pendingDeleteUser !== null}
        onClose={() => setPendingDeleteUser(null)}
        closeDrawer={() => setPendingDeleteUser(null)}
      />
    </div>
  );
}
