import { useCallback, useEffect, useState } from "react";

// UI - libs
import {
  Grid,
  Stack,
  Button,
  Paper,
  LinearProgress,
  Box,
  CircularProgress,
} from "@mui/material";
import { RefreshSharp } from "@mui/icons-material";
import {
  DataGridPremiumProps,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";

// UI - internal
import SafeleaseDataGrid from "../../../shared/safelease-data-grid";
import {
  usePlanUpgradesHistoryGridColDefs,
  statusComparator,
} from "./planUpgradesHistoryGridColDefs";
import { primaryColorButton } from "../../../styles/mui-overrides";
import TitleHeader from "../../../shared/title-header";

// Data
import { useQuery } from "@apollo/client";
import { getUpgradeProtectionPlanConfigs } from "../../../queries";
import { PlanUpgradesDataGridDetailPanel } from "./PlanUpgradesDataGridDetailPanel";

// Utils
import dayjs, { Dayjs } from "dayjs";
import useWindowSize from "../../../utilities/useWindowSize";
import { DialogType } from "../ScheduleUpgradeForm/ScheduleUpgradeFormDialog";

interface PlanUpgradesOverviewProps {
  setDialogOpen: (type: DialogType) => void;
}

export function PlanUpgradesOverview({
  setDialogOpen,
}: PlanUpgradesOverviewProps) {
  const size = useWindowSize();
  const { columns: planUpgradesHistoryGridColDefs } = usePlanUpgradesHistoryGridColDefs(setDialogOpen);
  /* Refresh the report every 15 seconds */
  const [countdownUntilNextRefresh, setCountdownUntilNextRefresh] =
    useState<Dayjs>(dayjs().add(15, "seconds"));

  const planUpgradeConfigsQuery = useQuery(getUpgradeProtectionPlanConfigs, {
    variables: {},
  });
  // State to force UI re-render
  const [_forceRender, setForceRender] = useState(0);

  useEffect(() => {
    // Interval for querying every 15 seconds
    const queryInterval = setInterval(() => {
      if (countdownUntilNextRefresh.isBefore(dayjs())) {
        planUpgradeConfigsQuery.refetch();
        setCountdownUntilNextRefresh(dayjs().add(15, "seconds"));
      }
    }, 15000); // 15000ms = 15 seconds

    // Interval for UI refresh every 250ms
    const uiRefreshInterval = setInterval(() => {
      setForceRender((prev) => prev + 1); // Increment to trigger re-render
    }, 250); // 250ms

    // Clear intervals on component unmount
    return () => {
      clearInterval(queryInterval);
      clearInterval(uiRefreshInterval);
    };
  }, []);

  const planUpgradeConfigs =
    planUpgradeConfigsQuery.data?.getUpgradeProtectionPlanConfigs ?? [];

  /* planUpgradeConfigs array is in frozen strict mode, slice to make a copy */
  /* Opt for this sorting strategy for multi-column sorting to avoid confusion of */
  /* multiple column sorting order numbers appearing in the data grid column headers */
  const sortedPlanUpgradeConfigs = planUpgradeConfigs
    .slice()
    .sort(statusComparator);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps["getDetailPanelContent"]>
  >(({ row }) => <PlanUpgradesDataGridDetailPanel row={row} />, []);

  const percentageUntilNextRefresh =
    (countdownUntilNextRefresh.toDate().getTime() -
      dayjs().toDate().getTime()) /
    15000;

  const handleRefresh = () => {
    planUpgradeConfigsQuery.refetch();
    setCountdownUntilNextRefresh(dayjs().add(15, "seconds"));
  };

  return (
    <>
      <TitleHeader title="Plan Upgrades" />
      <Paper
        elevation={0}
        sx={{
          p: 2,
          border: "1px solid #EBEFF7",
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                sx={{ ...primaryColorButton, fontWeight: "initial" }}
                onClick={() => setDialogOpen(DialogType.SCHEDULE_UPGRADE)}
              >
                Create New Plan Upgrade
              </Button>
              <RefreshButton
                loading={planUpgradeConfigsQuery.loading}
                value={percentageUntilNextRefresh}
                handleClick={handleRefresh}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: size.height - 270, width: "100%" }}>
              <SafeleaseDataGrid
                slots={{
                  toolbar: ExportToolbar,
                }}
                autosizeOnMount
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      relationshipId: false,
                      locationId: false
                    }
                  }
                }}
                columns={planUpgradesHistoryGridColDefs}
                rows={sortedPlanUpgradeConfigs}
                getDetailPanelHeight={() => "auto"}
                getDetailPanelContent={getDetailPanelContent}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

function ExportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        sx={{ textTransform: "none", ml: "auto" }}
        printOptions={{ disableToolbarButton: true }}
      />
    </GridToolbarContainer>
  );
}

function RefreshButton({ value, handleClick, loading }) {
  return (
    <Box
      sx={{
        width: 40,
        border: "1px solid #EBEFF7",
        p: 0.5,
        borderRadius: 1,
        cursor: "pointer",
        transition: "150ms",
        "&:hover": {
          transition: "150ms",
          bgcolor: "rgba(0,0,0,0.05)",
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {loading ? <CircularProgress /> : <RefreshSharp />}
      </Box>
      <LinearProgress
        value={value * 100}
        variant="determinate"
        sx={{ borderRadius: "50rem" }}
      />
    </Box>
  );
}
